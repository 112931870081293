import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState, DashboardRefreshConditionsState } from "../types";

const ENTITY = "dashboardRefreshConditions";

const initialState: DashboardRefreshConditionsState = {
  shouldRefreshData: true,
};

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {
    setShouldRefreshData: (state, action: PayloadAction<boolean>) => {
      state.shouldRefreshData = action.payload;
    },
  },
});
const getShouldRefreshData = ({
  dashboardRefreshConditions: { shouldRefreshData },
}: AppState) => shouldRefreshData;

export const dashboardRefreshConditionsSelectors = {
  getShouldRefreshData,
};

export const dashboardRefreshConditionsActions = {
  ...slice.actions,
};

export default slice.reducer;
