import {
  PATH_OAUTH_RESPONSE,
  PATH_RECOVER_PASSWORD,
  PATH_UPDATE_EMAIL_VERIFICATION,
} from "@deep-consulting-solutions/auth-web";

export const ROUTES = {
  login: {
    path: "/login",
  },
  logout: {
    path: "/logout",
  },
  forgot: {
    path: "/forgot-password",
  },
  settings: {
    path: "/settings",
    title: "Security Settings",
  },
  oauthResponse: {
    path: PATH_OAUTH_RESPONSE,
  },
  recoverPassword: {
    path: PATH_RECOVER_PASSWORD,
  },
  updateEmailVerification: {
    path: PATH_UPDATE_EMAIL_VERIFICATION,
  },
  home: {
    path: "/home",
    title: "Home",
  },
  profile: {
    path: "/profile",
    title: "Profile",
  },
  dependents: {
    path: "/dependents",
    title: "Dependents",
  },
  subscriptions: {
    path: "/subscriptions",
    title: "Subscriptions",
  },
  subRequest: {
    path: "/sub-request",
  },
  freeSubRequest: {
    path: "/free-sub-request",
  },
  freeSubList: {
    path: "/free-sub-List",
  },
  emergencyNetworkFreeSubList: {
    path: "/emergency-network-sub-List",
  },
  subList: {
    path: "/sub-List",
  },
  freeApplication: {
    path: "/free-application",
  },
  emergencyNetworkApplication: {
    path: "/emergency-network-application",
  },
  freeApplicationLogin: {
    path: "/free-application-login",
  },
  emergencyNetworkApplicationLogin: {
    path: "/emergency-network-application-login",
  },
  subType: {
    path: "/sub-type",
  },
  originOfInterest: {
    path: "/origin-of-interest",
  },
  competingPlans: {
    path: "/competing-plans",
  },
  subRequestSuccess: {
    path: "/sub-request-success",
  },
  regularApplication: {
    path: "/regular-application",
  },
  regularApplicationLogin: {
    path: "/regular-application-login",
  },
  regularApplicationLoginPostLogin: {
    path: "/regular-application-post-login",
  },
  contractSigning: {
    path: "/contract-signing",
  },
  salaryDeductionForm: {
    path: "/salary-deduction-form",
  },
  subscriptionRequested: {
    path: "/subscription-requested",
  },
  tempCustomerPortalAddOrUpgradeSubscription: {
    path: "/temp-customer-portal",
  },
  storeSubscriptionData: {
    path: "/store-subscription-data",
  },
  completeCustomerMandatoryInfo: {
    path: "/complete-profile",
    title: "Complete Profile",
  },
  dashboard: {
    path: "/dashboard",
    title: "Home",
  },
  dashboardProfile: {
    path: "/dashboard/profile",
    title: "Profile",
  },
  dashboardMembership: {
    path: "/dashboard/membership",
    title: "Membership",
  },
  dashboardMembershipDependent: {
    path: "/dashboard/membership/dependents",
    title: "Membership Dependents",
  },
  dashboardCreditCard: {
    path: "/dashboard/credit-cards",
    title: "Credit Cards",
  },
  dashboardRefunds: {
    path: "/dashboard/refunds",
    title: "Refunds",
  },
  dashboardAuth: {
    path: "/dashboard/authentication",
    title: "Authentication",
  },
  dashboardInvoice: {
    path: "/dashboard/invoices",
    title: "Invoice",
  },
  payments: {
    path: "/payments",
    title: "Payments",
  },
  completePayment: {
    path: "/complete-payment",
    title: "Complete Payment",
  },
  activation: {
    path: "/activation",
  },
  activationExpired: {
    path: "/activation-expired",
  },
  activationWelcome: {
    path: "/activation/welcome",
  },
  dashboardUpdateDependent: {
    path: "/dashboard/update-dependent",
    title: "Update Profile",
  },
  accountActivation: {
    path: "/activation/account",
  },
  firstPayment: {
    path: "/first-payment",
    title: "First Payment",
  },
  locationCollector: {
    path: "/location-collector",
    title: "BE Location Collector",
  },
  countrySelection: {
    path: "/country-selection",
    title: "Country Selection",
  },
  emergencyNetworkCountrySelection: {
    path: "/emergency-network-country-selection",
    title: "Country Selection",
  },
  planSelection: {
    path: "/plan-selection",
    title: "Plan Selection",
  },
  emergencyNetworkPlanSelection: {
    path: "/emergency-network-plan-selection",
    title: "Plan Selection",
  },
  countrySelectionFlowAuth: {
    path: "/country-selection-auth",
    title: "Sign up",
  },
};
