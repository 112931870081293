export const scrollToTopOfScreen = (smooth = true) => {
  window.scrollTo({
    top: 0,
    behavior: smooth ? "smooth" : "auto",
  });
};

export const scrollToFirstError = () => {
  setTimeout(() => {
    const allErrors = document.querySelectorAll(
      ".MuiFormHelperText-root.Mui-error"
    );
    if (allErrors && allErrors.length > 0) {
      let firstError: Element | undefined;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < allErrors.length; i++) {
        const el = allErrors[i];
        if (el && el.innerHTML) {
          firstError = el;
          break;
        }
      }
      if (firstError) {
        const y = firstError.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: y - 100,
          behavior: "smooth",
        });
      }
    }
  }, 0);
};
