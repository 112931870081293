import { pkAuthActions } from "@deep-consulting-solutions/auth-web";
import {
  Dependent,
  Document,
  MainBeneficiary,
  Company,
  Subscription,
  SubscriptionPlanEnum,
  SubscriptionStatusEnum,
  PaymentMethodEnum,
  SubscriptionEmployee,
  UploadDocumentsWithQuestions,
  SigningTicket,
  SubscriptionPaymentStatusEnum,
  SubscriptionPaymentBreakdown,
  CopyWithOptionalProps,
  PostSubscriptionRequestAfterLogin,
  AllDataForSingleSubscription,
  PaymentFrequencyEnum,
  SubscriptionWithDetails,
} from "@deep-consulting-solutions/be2-constants";
import {
  createAction,
  createAsyncThunk,
  createSlice,
  DeepPartial,
  PayloadAction,
} from "@reduxjs/toolkit";
import { db } from "helpers";
import { omit } from "lodash";
import { ID_PLACEHOLDER, ROUTES } from "AppCustomer/configs";
import { AxiosError } from "axios";
import { contactRequests } from "../contact";
import * as subRequestRequests from "./requests";
import {
  prepareDocumentsPayload,
  preparePostLoginPayload,
  convertSubscriptionToSubscriptionState,
  convertSubscriptionAllDataToSubscriptionState,
} from "./helpers";
import {
  AppState,
  AppDispatch,
  SubRequestState,
  DocumentsWithQuestions,
} from "../types";

const ENTITY = "subRequest";

const initialState: SubRequestState = {
  status: SubscriptionStatusEnum.DRAFT,
  email: null,
  selectedPlans: [],
  mainBeneficiary: null,
  dependents: [],
  hasUserDocsConsent: false,
  otherOriginOfInterest: null,
  paymentMethod: undefined,
  contractZohoSignUrl: null,
  contractSigningStatus: null,
  contractSigningTicketId: null,
  salaryDeductionFormZohoSignUrl: null,
  salaryDeductionFormSigningStatus: null,
  salaryDeductionFormSigningTicketId: null,
  activationFee: null,
  quarterlyPaymentAmount: null,
  paymentStatus: SubscriptionPaymentStatusEnum.PENDING_FIRST_PAYMENT,
  employmentInformation: undefined,
  routeFrom: "",
};

const resetSubRequest = createAction(`${ENTITY}/resetSubRequest`);

const tokenExpired = createAction(`${ENTITY}/tokenExpired`);

const fetchSubscriptions = createAsyncThunk<
  { subscription: (Subscription & SubscriptionWithDetails) | null },
  { allowPostLoginActiveSubscription: boolean } | undefined,
  { state: AppState; dispatch: AppDispatch }
>(`${ENTITY}/getSubscriptions`, async (filter, { getState }) => {
  const { isAuthorised, contact } = getState().pkAuth;

  if (isAuthorised) {
    const subscriptions = await subRequestRequests.fetchSubscriptionsReq();
    const activeSubscription = subscriptions.find(
      (subscription) =>
        subscription.mainBeneficiary.email === contact?.email &&
        ((!filter?.allowPostLoginActiveSubscription &&
          subscription.status === SubscriptionStatusEnum.DRAFT) ||
          !!filter?.allowPostLoginActiveSubscription)
    );
    return { subscription: activeSubscription || null };
  }

  return { subscription: null };
});

const fetchSubscriptionData = createAsyncThunk<
  { subscription: (AllDataForSingleSubscription & SubRequestState) | null },
  string | undefined,
  { state: AppState; dispatch: AppDispatch }
>(`${ENTITY}/getSubscriptionAllData`, async (id, { getState }) => {
  const { isAuthorised } = getState().pkAuth;

  const { id: subRequestId } = getState().subRequest;
  const subscriptionId = id || subRequestId;

  if (isAuthorised && !!subscriptionId) {
    const subscription = await subRequestRequests.fetchSubscriptionDataReq(
      subscriptionId
    );
    return {
      subscription: {
        ...getState().subRequest,
        ...subscription,
      },
    };
  }

  return { subscription: null };
});

const fetchSubscriptionPayments = createAsyncThunk<
  { subscription: Subscription | null },
  undefined,
  { state: AppState; dispatch: AppDispatch }
>(`${ENTITY}/fetchSubscriptionPayments`, async (__, { getState }) => {
  const data = await subRequestRequests.fetchSubscriptionPayments();
  return {
    subscription: {
      ...(getState().subRequest as Subscription),
      // payments: data,
      isFirstPaymentMade: !!data[0],
    },
  };
});

const createSubscription = createAsyncThunk<
  { subscription: Subscription },
  { subscription?: Subscription },
  { state: AppState; dispatch: AppDispatch }
>(`${ENTITY}/createSubscription`, async ({ subscription }, { getState }) => {
  const { isAuthorised } = getState().pkAuth;
  const { mainBeneficiary, selectedPlans } = getState().subRequest;

  if (isAuthorised) {
    const res = await subRequestRequests.createSubscription(
      subscription,
      selectedPlans
    );
    return { subscription: res };
  }

  return {
    subscription: {
      id: "",
      status: SubscriptionStatusEnum.DRAFT,
      selectedPlans,
      mainBeneficiary: {
        id: "",
        zohoId: "",
        firstName: "",
        lastName: "",
        ...(mainBeneficiary?.email ? { email: mainBeneficiary.email } : {}),
      },
      dependents: [],
      otherOriginOfInterest: null,
      paymentStatus: SubscriptionPaymentStatusEnum.PENDING_FIRST_PAYMENT,
    } as Subscription,
  };
});

const checkIfEmailExists = createAsyncThunk<
  { isNewEmail: boolean },
  { email: string; setValue?: boolean },
  { state: AppState; dispatch: AppDispatch }
>(`${ENTITY}/checkIfEmailExists`, async ({ email, setValue }) => {
  if (setValue !== undefined) {
    return { isNewEmail: setValue };
  }

  const res = await subRequestRequests.checkIfEmailExists(email);

  return { isNewEmail: !res.exists };
});

const setIsNewEmail = createAsyncThunk<
  { isNewEmail: boolean },
  { value: boolean },
  { state: AppState; dispatch: AppDispatch }
>(`${ENTITY}/setIsNewEmail`, ({ value }) => {
  return { isNewEmail: value };
});

const updateMainBeneficiary = createAsyncThunk<
  MainBeneficiary,
  {
    mainBeneficiary: DeepPartial<
      Omit<MainBeneficiary, "id"> & {
        newCompanyName?: string;
        preferredHospitalId?: string;
      }
    >;
    source?: "dashboard" | "sub-request";
  },
  { state: AppState; dispatch: AppDispatch }
>(
  `${ENTITY}/updateMainBeneficiary`,
  async (
    {
      mainBeneficiary: { newCompanyName, ...mainBeneficiary },
      source = "sub-request",
    },
    { getState, dispatch }
  ) => {
    const { isAuthorised } = getState().pkAuth;
    const { id, mainBeneficiary: stateMainBeneficiary } = getState().subRequest;

    if (isAuthorised && id) {
      const modifiedBeneficiary = {
        ...omit(mainBeneficiary, "preferredHospital", "company"),
        preferredHospitalId:
          mainBeneficiary.preferredHospital?.id ||
          mainBeneficiary?.preferredHospitalId,
        companyId: mainBeneficiary.company?.id,
        newCompanyName:
          !mainBeneficiary.company || mainBeneficiary.company?.isNew
            ? newCompanyName
            : undefined,
      };

      let res: Subscription;

      if (source === "dashboard") {
        res = await subRequestRequests.updateMainBeneficiaryDashboard(
          id,
          modifiedBeneficiary
        );
      } else {
        res = await subRequestRequests.updateMainBeneficiary(
          id,
          modifiedBeneficiary
        );
      }

      dispatch(
        pkAuthActions.updateContact({
          email: res.mainBeneficiary.email,
          phone: res.mainBeneficiary.phone,
          lastName: res.mainBeneficiary.lastName,
          firstName: res.mainBeneficiary.firstName,
        })
      );

      return res.mainBeneficiary;
    }

    const finalMainBeneficiary: MainBeneficiary = {
      ...omit(mainBeneficiary, "company"),
      id: stateMainBeneficiary?.id || "",
      firstName:
        mainBeneficiary.firstName || stateMainBeneficiary?.firstName || "",
      lastName:
        mainBeneficiary.lastName || stateMainBeneficiary?.lastName || "",
      zohoId: mainBeneficiary.zohoId!,
    };

    if (mainBeneficiary.company) {
      finalMainBeneficiary.company = mainBeneficiary.company;
    } else {
      finalMainBeneficiary.company = {
        id: "",
        isNew: true,
        name: newCompanyName!,
      };
    }

    return finalMainBeneficiary;
  }
);

const updateMainBeneficiaryDocuments = createAsyncThunk<
  { documentsData: DocumentsWithQuestions },
  { documents: UploadDocumentsWithQuestions },
  { state: AppState; dispatch: AppDispatch }
>(
  `${ENTITY}/updateMainBeneficiaryDocuments`,
  async ({ documents }, { getState }) => {
    const { isAuthorised } = getState().pkAuth;

    if (isAuthorised) {
      const { id } = getState().subRequest;
      const mbDocuments =
        getState().subRequest.mainBeneficiary?.documents || [];

      documents.documents = prepareDocumentsPayload(
        mbDocuments,
        documents.documents
      );

      const res = await subRequestRequests.updateMainBeneficiaryDocumentsReq(
        id || "",
        documents
      );

      return {
        documentsData: {
          documents: res.documents || [],
          isInadmissibleToEnterUs: res.isInadmissibleToEnterUs,
          isEligibleForFreeVisa: res.isEligibleForFreeVisa,
        },
      };
    }

    return {
      documentsData: {
        ...documents,
        documents: documents.documents as Document[],
      },
    };
  }
);

const upsertDependent = createAsyncThunk<
  { oldDependentId: string; newDependent: Dependent },
  { dependent: Partial<Dependent> },
  { state: AppState; dispatch: AppDispatch }
>(
  `${ENTITY}/upsertDependent`,
  async ({ dependent }, { getState, rejectWithValue }) => {
    try {
      const { isAuthorised } = getState().pkAuth;
      const { id } = getState().subRequest;

      if (isAuthorised && id) {
        const res = await subRequestRequests.upsertDependent(
          id,
          omit(dependent as Dependent, ["isClaimed", "hasActiveSubscription"])
        );

        return {
          oldDependentId: dependent.id || "",
          newDependent: res,
        };
      }

      return {
        oldDependentId: dependent.id || "",
        newDependent: dependent as Dependent,
      };
    } catch (error) {
      const e = error as AxiosError;
      return rejectWithValue(e.response?.data?.message);
    }
  }
);

const updateDependentDocuments = createAsyncThunk<
  { dependentId: string; documentsData: DocumentsWithQuestions },
  { dependentId: string; documents: UploadDocumentsWithQuestions },
  { state: AppState; dispatch: AppDispatch }
>(
  `${ENTITY}/updateDependentDocuments`,
  async ({ dependentId, documents }, { getState }) => {
    const { isAuthorised } = getState().pkAuth;

    if (isAuthorised) {
      const { id } = getState().subRequest;

      const { zohoId = undefined, documents: dependentDocuments } =
        getState().subRequest.dependents.find(
          (dependent) => dependent.id === dependentId
        ) || {};

      const res = await subRequestRequests.updateDependentDocumentsReq(
        id || "",
        dependentId,
        {
          ...documents,
          beneficiaryZohoId: zohoId,
          documents: prepareDocumentsPayload(
            dependentDocuments,
            documents.documents
          ),
        }
      );

      return {
        dependentId,
        documentsData: {
          documents: res.documents || [],
          isInadmissibleToEnterUs: res.isInadmissibleToEnterUs,
          isEligibleForFreeVisa: res.isEligibleForFreeVisa,
        },
      };
    }

    return {
      dependentId,
      documentsData: {
        ...documents,
        documents: documents.documents as Document[],
      },
    };
  }
);

const deleteDependent = createAsyncThunk<
  string,
  { dependentId: string },
  { state: AppState; dispatch: AppDispatch }
>(`${ENTITY}/deleteDependent`, async ({ dependentId }, { getState }) => {
  const { isAuthorised } = getState().pkAuth;
  if (isAuthorised && !dependentId.startsWith(ID_PLACEHOLDER)) {
    const { id } = getState().subRequest;
    await subRequestRequests.deleteDependent(id || "", dependentId);
    return dependentId;
  }

  return dependentId;
});

const saveHasUserDocsConsent = createAsyncThunk<
  { hasUserDocsConsent: boolean },
  { hasUserDocsConsent: boolean },
  { state: AppState; dispatch: AppDispatch }
>(`${ENTITY}/saveHasUserDocsConsent`, async ({ hasUserDocsConsent }) => {
  await subRequestRequests.saveHasUserDocsConsentReq();
  return { hasUserDocsConsent };
});

const saveSelectedPlans = createAsyncThunk<
  SubscriptionPlanEnum[],
  { selectedPlans: SubscriptionPlanEnum[] },
  { state: AppState; dispatch: AppDispatch }
>(`${ENTITY}/saveSelectedPlans`, async ({ selectedPlans }, { getState }) => {
  const { isAuthorised } = getState().pkAuth;
  if (isAuthorised) {
    const { id } = getState().subRequest;
    if (id) {
      const { data } = await subRequestRequests.saveSelectedPlans(
        id,
        selectedPlans
      );
      return data.selectedPlans;
    }
  }

  return selectedPlans;
});

const saveOriginOfInterest = createAsyncThunk<
  {
    originOfInterest: { id: string; name: string };
    otherOriginOfInterest: string | null;
  },
  {
    originOfInterest: { id: string; name: string };
    otherOriginOfInterest: string | null;
  },
  { state: AppState; dispatch: AppDispatch }
>(
  `${ENTITY}/saveOriginOfInterest`,
  async ({ originOfInterest, otherOriginOfInterest }, { getState }) => {
    const { isAuthorised } = getState().pkAuth;
    if (isAuthorised) {
      const { id } = getState().subRequest;
      await subRequestRequests.saveOriginOfInterest({
        subscriptionId: id || "",
        originOfInterestId: originOfInterest.id,
        otherOriginOfInterest,
      });

      return { originOfInterest, otherOriginOfInterest };
    }

    return { originOfInterest, otherOriginOfInterest };
  }
);

const updateSelectedPaymentMethod = createAsyncThunk<
  { paymentMethod: PaymentMethodEnum; paymentFrequency?: PaymentFrequencyEnum },
  {
    paymentMethod: PaymentMethodEnum;
    subscriptionId: string;
    paymentFrequency?: PaymentFrequencyEnum;
  },
  { state: AppState }
>(
  `${ENTITY}/updateSelectedPaymentMethod`,
  async ({ paymentMethod, subscriptionId, paymentFrequency }, { getState }) => {
    const { isAuthorised } = getState().pkAuth;

    if (isAuthorised) {
      await subRequestRequests.updatePaymentMethodReq({
        paymentMethod,
        subscriptionId,
        ...(paymentFrequency ? { paymentFrequency } : {}),
      });
    }

    return { paymentMethod };
  }
);

const updateSalaryDeductionInfo = createAsyncThunk<
  Partial<MainBeneficiary>,
  {
    company: Company;
    employee: CopyWithOptionalProps<
      SubscriptionEmployee,
      "employmentCommencementDate"
    >;
  },
  { state: AppState; dispatch: AppDispatch }
>(
  `${ENTITY}/updateSalaryDeductionInfo`,
  async ({ company, employee }, { getState }) => {
    const { isAuthorised } = getState().pkAuth;
    const { id = "" } = getState().subRequest;

    const companyId = company.id || undefined;

    if (isAuthorised) {
      const { contactPerson, ...employmentDetails } = employee;

      const body = {
        id,
        companyId,
        contactPerson,
        employmentDetails,
        ...(!companyId && { companyName: company.name }),
        companyDivision: company.department,
        companyAddress: {
          city: company.headquartersCity,
          pobox: company.headquartersPoBox,
          island: company.headquartersState,
          street: company.headquartersStreet,
          country: company.headquartersCountry,
        },
      };

      const res = await contactRequests.updateContact(body);
      return res;
    }

    return {
      company: { ...company, id: companyId || "", isNew: !companyId },
      employee: {
        ...employee,
        employmentCommencementDate: employee.employmentCommencementDate || "",
      },
    };
  }
);

const fetchContractSigningStatus = createAsyncThunk<
  Omit<SigningTicket, "signingUrl">,
  { ticketId: string }
>(`${ENTITY}/fetchContractSigningStatus`, async ({ ticketId }) => {
  const data = await subRequestRequests.fetchContractSigningStatusReq(ticketId);

  return data;
});

const fetchContractZohoSignUrl = createAsyncThunk<
  SigningTicket,
  { otp: string; subscriptionId: string }
>(`${ENTITY}/fetchContractZohoSignUrl`, async ({ otp, subscriptionId }) => {
  const data = await subRequestRequests.fetchContractZohoSignUrlReq(
    subscriptionId,
    otp
  );

  return data;
});

const fetchSalaryDeductionFormSigningStatus = createAsyncThunk<
  Omit<SigningTicket, "signingUrl">,
  { ticketId: string }
>(`${ENTITY}/fetchSalaryDeductionFormSigningStatus`, async ({ ticketId }) => {
  const data = await subRequestRequests.fetchSalaryDeductionFormSigningStatusReq(
    ticketId
  );

  return data;
});

const fetchSalaryDeductionFormZohoSignUrl = createAsyncThunk<
  SigningTicket,
  { otp: string; subscriptionId: string }
>(
  `${ENTITY}/fetchSalaryDeductionFormZohoSignUrl`,
  async ({ otp, subscriptionId }) => {
    const data = await subRequestRequests.fetchSalaryDeductionFormZohoSignUrlReq(
      subscriptionId,
      otp
    );

    return data as SigningTicket;
  }
);

const fetchActivationFee = createAsyncThunk<
  { activationFee: number | null },
  { subscriptionId: string }
>(`${ENTITY}/fetchActivationFee`, async ({ subscriptionId }) => {
  const data = await subRequestRequests.fetchActivationFeeReq(subscriptionId);
  return data;
});

const fetchQuarterlyPaymentAmount = createAsyncThunk<{
  quarterlyPaymentAmount: number | null;
}>(`${ENTITY}/fetchQuarterlyPaymentAmount`, async () => {
  const data = await subRequestRequests.fetchQuarterlyPaymentAmountReq();
  return data;
});

// TODO - should we add credit card to store?
// const fetchCreditCard = createAsyncThunk(
//   `${ENTITY}/fetchCreditCard`,
//   async () => {
//     const data = await subRequestRequests.fetchCreditCardReq();
//     return data;
//   }
// );

const updateSelectedPlans = createAction<SubscriptionPlanEnum>(
  `${ENTITY}/updateSelectedPlans`
);

const setMarkMinimalInformationChange = createAsyncThunk<
  { markMinimalInformationChange: boolean },
  { markMinimalInformationChange: boolean }
>(
  `${ENTITY}/markMinimalInformationChange`,
  ({ markMinimalInformationChange }) => {
    return {
      markMinimalInformationChange,
    };
  }
);

const fetchSubscriptionPaymentBreakdown = createAsyncThunk<
  SubscriptionPaymentBreakdown,
  { subscriptionId: string }
>(`${ENTITY}/getSubscriptionPaymentBreakdown`, async ({ subscriptionId }) => {
  const data = await subRequestRequests.getSubscriptionPaymentBreakdown(
    subscriptionId
  );
  return data;
});

const deleteDocument = createAsyncThunk<
  { documentId: string },
  { documentId: string; subscriptionId: string }
>(`${ENTITY}/deleteDocument`, async ({ documentId, subscriptionId }) => {
  await subRequestRequests.deleteDocument(subscriptionId, documentId);
  return { documentId };
});

const createSubscriptionFromState = createAsyncThunk<
  typeof PostSubscriptionRequestAfterLogin.Res.data,
  { trackProgress: (progressEvent: ProgressEvent<EventTarget>) => void },
  {
    state: AppState;
  }
>(
  `${ENTITY}/createSubscriptionFromState`,
  async ({ trackProgress }, { getState }) => {
    const subscription = preparePostLoginPayload(getState().subRequest);

    const res = await subRequestRequests.postSubscriptionRequest(
      subscription,
      trackProgress
    );

    return res;
  }
);

const setFreeSubscriptionStatusToPendingValidation = createAsyncThunk<
  Subscription,
  {
    id: string;
  }
>(`${ENTITY}/setFreeSubscriptionStatusToPendingValidation`, async ({ id }) => {
  const res = await subRequestRequests.setFreeSubscriptionStatusToPendingValidation(
    id
  );

  return res;
});

const saveUserEmail = createAsyncThunk<
  { email: string; isNewEmail: boolean },
  {
    email: string;
  }
>(`${ENTITY}/saveUserEmail`, async ({ email }, { rejectWithValue }) => {
  const res = await subRequestRequests.checkIfEmailExists(email);

  if (res.exists) {
    return rejectWithValue({ email, isNewEmail: false });
  }
  return { email, isNewEmail: true };
});

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {
    authorizedFrom: (state, action: PayloadAction<string>) => {
      state.routeFrom = action.payload;
    },
    saveCountryFlow: (state, action: PayloadAction<string>) => {
      if (state.mainBeneficiary) {
        state.mainBeneficiary.country = action.payload;
      } else {
        state.mainBeneficiary = { country: action.payload } as MainBeneficiary;
      }
      state.country = action.payload;
      state.isCountryFlow = true;
    },
  },
  extraReducers: (builders) =>
    builders
      .addCase(pkAuthActions.logout.fulfilled, () => {
        db.files.clear();
        return { ...initialState };
      })
      .addCase(resetSubRequest, () => {
        db.files.clear();
        return { ...initialState };
      })
      .addCase(tokenExpired, () => {
        // hard redirect to login page.
        window.location.href = `${window.location.origin}${ROUTES.login.path}`;
        return { ...initialState };
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        return {
          ...state,
          ...convertSubscriptionToSubscriptionState(
            action.payload.subscription
          ),
        };
      })
      .addCase(setMarkMinimalInformationChange.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      })
      .addCase(fetchSubscriptionData.fulfilled, (state, action) => {
        return {
          ...state,
          ...convertSubscriptionAllDataToSubscriptionState(
            action.payload.subscription
          ),
        };
      })
      .addCase(fetchSubscriptionPayments.fulfilled, (state, action) => {
        return {
          ...state,
          ...convertSubscriptionToSubscriptionState(
            action.payload.subscription
          ),
        };
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        return {
          ...state,
          ...convertSubscriptionToSubscriptionState(
            action.payload.subscription
          ),
        };
      })
      .addCase(checkIfEmailExists.fulfilled, (state, action) => {
        state.isNewEmail = action.payload.isNewEmail;
      })
      .addCase(setIsNewEmail.fulfilled, (state, action) => {
        state.isNewEmail = action.payload.isNewEmail;
      })
      .addCase(updateMainBeneficiary.fulfilled, (state, action) => {
        state.mainBeneficiary = {
          ...state.mainBeneficiary,
          ...action.payload,
        };
      })
      .addCase(updateMainBeneficiaryDocuments.fulfilled, (state, action) => {
        if (state.mainBeneficiary) {
          state.mainBeneficiary = {
            ...state.mainBeneficiary,
            ...action.payload.documentsData,
          };
        }
      })
      .addCase(upsertDependent.fulfilled, (state, action) => {
        if (action.payload.newDependent.id.startsWith(ID_PLACEHOLDER)) {
          // user is not logged in
          const dependentIndexToUpdate = state.dependents.findIndex(
            (dependent) => dependent.id === action.payload.oldDependentId
          );
          if (dependentIndexToUpdate !== -1) {
            // editing dependent
            state.dependents[dependentIndexToUpdate] = {
              ...state.dependents[dependentIndexToUpdate],
              ...action.payload.newDependent,
            };
          } else {
            // creating dependent
            state.dependents = [
              ...state.dependents,
              action.payload.newDependent,
            ];
          }
        } else if (action.payload.oldDependentId.startsWith(ID_PLACEHOLDER)) {
          // user is logged in and creating dependent
          state.dependents = [...state.dependents, action.payload.newDependent];
        } else {
          // user is logged in and editing dependent
          state.dependents = state.dependents.map((dependent) => {
            if (dependent.id === action.payload.oldDependentId) {
              return action.payload.newDependent;
            }
            return dependent;
          });
        }
      })
      .addCase(updateDependentDocuments.fulfilled, (state, action) => {
        state.dependents = state.dependents.map((dependent) => {
          if (dependent.id === action.payload.dependentId) {
            return {
              ...dependent,
              ...action.payload.documentsData,
            };
          }
          return dependent;
        });
      })
      .addCase(deleteDependent.fulfilled, (state, action) => {
        state.dependents = state.dependents.filter(
          (dependent) => dependent.id !== action.payload
        );
      })
      .addCase(saveHasUserDocsConsent.fulfilled, (state, action) => {
        state.hasUserDocsConsent = action.payload.hasUserDocsConsent;
      })
      .addCase(saveSelectedPlans.fulfilled, (state, action) => {
        state.selectedPlans = action.payload;
      })
      .addCase(saveOriginOfInterest.fulfilled, (state, action) => {
        const { originOfInterest, otherOriginOfInterest } = action.payload;
        state.originOfInterest = originOfInterest;
        state.otherOriginOfInterest = otherOriginOfInterest;
      })
      .addCase(updateSalaryDeductionInfo.fulfilled, (state, action) => {
        if (state.mainBeneficiary) {
          state.mainBeneficiary = {
            ...state.mainBeneficiary,
            ...action.payload,
            employee: action.payload.employee
              ? {
                  ...action.payload.employee,
                  contactPerson: action.payload.employee.contactPerson
                    ? {
                        ...action.payload.employee?.contactPerson,
                        id: "",
                      }
                    : action.payload.employee.contactPerson,
                }
              : action.payload.employee,
          };
        }
      })
      .addCase(updateSelectedPaymentMethod.fulfilled, (state, action) => {
        state.paymentMethod = action.payload.paymentMethod;
        state.paymentFrequency = action.payload.paymentFrequency;
      })
      .addCase(fetchContractSigningStatus.fulfilled, (state, action) => {
        state.contractSigningStatus = action.payload.status;
      })
      .addCase(fetchContractZohoSignUrl.fulfilled, (state, action) => {
        state.contractSigningTicketId = action.payload.id;
        state.contractSigningStatus = action.payload.status;
        state.contractZohoSignUrl = action.payload.signingUrl;
      })
      .addCase(
        fetchSalaryDeductionFormSigningStatus.fulfilled,
        (state, action) => {
          state.salaryDeductionFormSigningStatus = action.payload.status;
        }
      )
      .addCase(
        fetchSalaryDeductionFormZohoSignUrl.fulfilled,
        (state, action) => {
          state.salaryDeductionFormSigningTicketId = action.payload.id;
          state.salaryDeductionFormSigningStatus = action.payload.status;
          state.salaryDeductionFormZohoSignUrl = action.payload.signingUrl;
        }
      )
      .addCase(fetchActivationFee.fulfilled, (state, action) => {
        state.activationFee = action.payload.activationFee;
      })
      .addCase(fetchQuarterlyPaymentAmount.fulfilled, (state, action) => {
        state.quarterlyPaymentAmount = action.payload.quarterlyPaymentAmount;
      })
      .addCase(updateSelectedPlans, (state, action) => {
        if (action.payload === SubscriptionPlanEnum.FREE) {
          state.selectedPlans = [SubscriptionPlanEnum.FREE];
        } else if (
          action.payload === SubscriptionPlanEnum.EMERGENCY_NETWORK_ACCESS
        ) {
          state.selectedPlans = [SubscriptionPlanEnum.EMERGENCY_NETWORK_ACCESS];
        } else {
          const index = state.selectedPlans.findIndex((plan) =>
            action.payload === SubscriptionPlanEnum.GROUND
              ? plan === SubscriptionPlanEnum.GROUND
              : plan.includes("Sky")
          );

          if (index === -1) {
            state.selectedPlans = state.selectedPlans.filter(
              (plan) =>
                plan !== SubscriptionPlanEnum.FREE &&
                plan !== SubscriptionPlanEnum.EMERGENCY_NETWORK_ACCESS
            );

            state.selectedPlans.push(action.payload);
          } else if (state.selectedPlans[index] === action.payload) {
            state.selectedPlans.splice(index, 1);

            // add emergency network access plan if no plan exists.
            if (!state.selectedPlans.length) {
              state.selectedPlans = [
                SubscriptionPlanEnum.EMERGENCY_NETWORK_ACCESS,
              ];
            }
          } else {
            state.selectedPlans.splice(index, 1, action.payload);
          }
        }
      })
      .addCase(fetchSubscriptionPaymentBreakdown.fulfilled, (state, action) => {
        state.activationFee = action.payload.activationFee;
        state.subscriptionPaymentBreakdown = {
          ...state.subscriptionPaymentBreakdown,
          ...action.payload,
        };
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        const { documentId } = action.payload;

        if (state.mainBeneficiary) {
          state.mainBeneficiary.documents = state.mainBeneficiary?.documents?.filter(
            ({ id }) => id !== documentId
          );
        }

        if (state.dependents) {
          state.dependents = state.dependents.map((dependent) => ({
            ...dependent,
            documents: dependent.documents?.filter(
              ({ id }) => id !== documentId
            ),
          }));
        }
      })
      .addCase(saveUserEmail.fulfilled, (state, action) => {
        if (state.mainBeneficiary) {
          state.mainBeneficiary.email = action.payload.email;
        } else {
          state.mainBeneficiary = {
            email: action.payload.email,
          } as MainBeneficiary;
        }
        state.email = action.payload.email;
        state.isNewEmail = action.payload.isNewEmail;
      })
      .addCase(createSubscriptionFromState.fulfilled, (state, action) => {
        const { dependents, mainBeneficiary } = state;

        return {
          ...state,
          ...convertSubscriptionToSubscriptionState({
            ...action.payload,
            mainBeneficiary: {
              ...action.payload.mainBeneficiary,
              documents: mainBeneficiary?.documents,
              isEligibleForFreeVisa: mainBeneficiary?.isEligibleForFreeVisa,
              isInadmissibleToEnterUs: mainBeneficiary?.isInadmissibleToEnterUs,
            },
            dependents: dependents
              ? dependents.reduce((accumulator, dependent) => {
                  const zohoId =
                    action.payload.dependentsZohoIdMap[
                      dependent.id.replace(ID_PLACEHOLDER, "")
                    ];

                  const dependentInPostLogin = action.payload.dependents?.find(
                    (dep) => dep.zohoId === zohoId
                  );

                  if (dependentInPostLogin) {
                    accumulator.push({
                      ...dependentInPostLogin,
                      documents: dependent.documents,
                      id: dependentInPostLogin.contactId,
                      isEligibleForFreeVisa: dependent.isEligibleForFreeVisa,
                      isInadmissibleToEnterUs:
                        dependent.isInadmissibleToEnterUs,
                    });
                  }

                  return accumulator;
                }, [] as Dependent[])
              : dependents,
          }),
          paymentMethod: undefined,
        };
      })
      .addCase(
        setFreeSubscriptionStatusToPendingValidation.fulfilled,
        (state, action) => {
          return {
            ...state,
            ...action.payload,
          };
        }
      ),
});

const getIsNewEmail = ({ subRequest: { isNewEmail } }: AppState) => isNewEmail;

const getEmail = ({ subRequest: { email } }: AppState) => email;

const getStatus = ({ subRequest: { status } }: AppState) => status;

const getMainBeneficiary = ({ subRequest: { mainBeneficiary } }: AppState) =>
  mainBeneficiary;

const getDependents = ({ subRequest: { dependents } }: AppState) => dependents;

const getHasUserDocsConsent = ({
  subRequest: { hasUserDocsConsent },
}: AppState) => hasUserDocsConsent;

const getSubrequestState = ({
  subRequest: { id, status, email, selectedPlans, isCountryFlow },
}: AppState) => ({
  id,
  status,
  email,
  selectedPlans,
  isCountryFlow,
});

const getIsFree = (state: AppState) =>
  state.subRequest.selectedPlans[0] === SubscriptionPlanEnum.FREE;

const getOriginOfInterest = ({
  subRequest: { originOfInterest, otherOriginOfInterest },
}: AppState) => ({
  originOfInterest,
  otherOriginOfInterest,
});

const getPaymentMethod = ({ subRequest: { paymentMethod } }: AppState) =>
  paymentMethod;

const getPaymentFrequency = ({ subRequest: { paymentFrequency } }: AppState) =>
  paymentFrequency;

const getContractSigningTicketId = ({
  subRequest: { contractSigningTicketId },
}: AppState) => contractSigningTicketId;

const getContractSigningStatus = ({
  subRequest: { contractSigningStatus },
}: AppState) => contractSigningStatus;

const getContractZohoSignUrl = ({
  subRequest: { contractZohoSignUrl },
}: AppState) => contractZohoSignUrl;

const getSalaryDeductionFormSigningStatus = ({
  subRequest: { salaryDeductionFormSigningStatus },
}: AppState) => salaryDeductionFormSigningStatus;

const getSalaryDeductionFormZohoSignUrl = ({
  subRequest: { salaryDeductionFormZohoSignUrl },
}: AppState) => salaryDeductionFormZohoSignUrl;

const getCountry = ({ subRequest: { mainBeneficiary, country } }: AppState) =>
  mainBeneficiary?.country || country;

const getSalaryDeductionFormSigningTicketId = ({
  subRequest: { salaryDeductionFormSigningTicketId },
}: AppState) => salaryDeductionFormSigningTicketId;

const getActivationFee = ({ subRequest: { activationFee } }: AppState) =>
  activationFee;

const getRouteFrom = ({ subRequest: { routeFrom } }: AppState) => routeFrom;

const getPaymentDetails = ({ subRequest }: AppState) => {
  return subRequest.paymentDetails;
};

const getSubscriptionPaymentBreakdown = ({ subRequest }: AppState) => {
  return subRequest.subscriptionPaymentBreakdown;
};

const getQuarterlyPaymentAmount = ({
  subRequest: { quarterlyPaymentAmount },
}: AppState) => quarterlyPaymentAmount;

const getSelectedPlans = ({ subRequest }: AppState) => subRequest.selectedPlans;

const getMarkMinimalInformationChange = ({ subRequest }: AppState) => {
  return !!subRequest.markMinimalInformationChange;
};

const getPaymentStatus = ({ subRequest }: AppState) => subRequest.paymentStatus;

const getDocumentsIds = ({ subRequest }: AppState) => {
  const mainBeneficiaryDocuments =
    subRequest.mainBeneficiary?.documents?.map(({ id }) => id) || [];

  const dependentDocuments = (
    subRequest.dependents.map(
      ({ documents }) => documents?.map(({ id }) => id) || []
    ) || []
  ).flat(2);

  return [...mainBeneficiaryDocuments, ...dependentDocuments];
};

const getEmploymentInformation = ({
  subRequest: { employmentInformation },
}: AppState) => employmentInformation;

export const subRequestsSelectors = {
  getIsNewEmail,
  getEmail,
  getStatus,
  getSubrequestState,
  getMainBeneficiary,
  getDependents,
  getHasUserDocsConsent,
  getIsFree,
  getOriginOfInterest,
  getPaymentMethod,
  getPaymentFrequency,
  getContractSigningTicketId,
  getContractSigningStatus,
  getContractZohoSignUrl,
  getSalaryDeductionFormSigningStatus,
  getSalaryDeductionFormZohoSignUrl,
  getSalaryDeductionFormSigningTicketId,
  getActivationFee,
  getQuarterlyPaymentAmount,
  getSelectedPlans,
  getMarkMinimalInformationChange,
  getPaymentStatus,
  getDocumentsIds,
  getPaymentDetails,
  getEmploymentInformation,
  getSubscriptionPaymentBreakdown,
  getRouteFrom,
  getCountry,
};

export const subRequestsActions = {
  ...slice.actions,
  tokenExpired,
  resetSubRequest,
  fetchSubscriptions,
  createSubscription,
  saveSelectedPlans,
  saveOriginOfInterest,
  updateMainBeneficiary,
  updateMainBeneficiaryDocuments,
  upsertDependent,
  updateDependentDocuments,
  deleteDependent,
  saveHasUserDocsConsent,
  updateSalaryDeductionInfo,
  updateSelectedPaymentMethod,
  fetchContractSigningStatus,
  fetchContractZohoSignUrl,
  fetchSalaryDeductionFormSigningStatus,
  fetchSalaryDeductionFormZohoSignUrl,
  fetchActivationFee,
  fetchQuarterlyPaymentAmount,
  updateSelectedPlans,
  fetchSubscriptionData,
  fetchSubscriptionPayments,
  checkIfEmailExists,
  setIsNewEmail,
  setMarkMinimalInformationChange,
  fetchSubscriptionPaymentBreakdown,
  deleteDocument,
  createSubscriptionFromState,
  setFreeSubscriptionStatusToPendingValidation,
  saveUserEmail,
};

export { subRequestRequests };

export default slice.reducer;
