export const getQueryParam = (key: string): string | undefined => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  return params[key];
};

export const appendQueryParam = (
  pathname: string,
  params: { key: string; value: string }[]
) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const previousParams = Object.fromEntries(urlSearchParams.entries());
  params.forEach(({ key, value }) => {
    previousParams[key] = value;
  });

  return `${pathname}?${Object.entries(previousParams)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&")}`;
};
