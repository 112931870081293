export enum UsCountriesEnum {
  US = "United States",
  AS = "American Samoa (non-citizen US National)",
  "United States" = "US",
  "American Samoa (non-citizen US National)" = "AS",
}

export enum UsFreeVisaCountriesEnum {
  CA = "Canada",
  BM = "Bermuda",
  FM = "Micronesia",
  MH = "Marshall Islands",
  PW = "Palau",
}

export enum UsEntryDocTypeEnum {
  Visa = "Visa",
  Permit = "Permit",
  ESTA = "ESTA",
  Other = "Other",
}

export interface OriginOfInterest {
  id: string;
  zohoId?: string;
  type: string;
}

export enum BloodTypesEnum {
  "A+" = "A+",
  "A-" = "A-",
  "B+" = "B+",
  "B-" = "B-",
  "O+" = "O+",
  "O-" = "O-",
  "AB+" = "AB+",
  "AB-" = "AB-",
}
