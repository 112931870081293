import { Hospital } from "@deep-consulting-solutions/be2-constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AppState, HospitalsState } from "../types";
import * as hospitalsRequests from "./requests";

const ENTITY = "hospitals";

const initialState: HospitalsState = {
  hospitals: [],
};

const fetchHospitals = createAsyncThunk<{ hospitals: Hospital[] }>(
  `${ENTITY}/fetchHospitals`,
  async () => {
    const hospitals = await hospitalsRequests.fetchHospitalsReq();
    return { hospitals };
  }
);

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {},
  extraReducers: (builders) =>
    builders.addCase(fetchHospitals.fulfilled, (state, action) => {
      state.hospitals = action.payload.hospitals;
    }),
});

const getHospitals = ({ hospitals: { hospitals } }: AppState) => hospitals;

export const hospitalsSelectors = {
  getHospitals,
};

export const hospitalsActions = {
  ...slice.actions,
  fetchHospitals,
};

export { hospitalsRequests };

export default slice.reducer;
