import React from "react";
import ReactDOM from "react-dom";
import { APP_CUSTOMER, APP_SALES, APP_WIDGETS } from "configs";
// import { notifications } from "services";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "services/pkInvoices";

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === APP_CUSTOMER) {
    return import("./AppCustomer");
  }
  if (process.env.REACT_APP_BUILD_TARGET === APP_SALES) {
    return import("./AppSales");
  }
  if (process.env.REACT_APP_BUILD_TARGET === APP_WIDGETS) {
    return import("./AppWidgets");
  }
  return Promise.reject(
    new Error(`No such build target: ${process.env.REACT_APP_BUILD_TARGET}`)
  );
}

// Import the entry point and render it's default export
importBuildTarget().then(({ default: Environment }) => {
  ReactDOM.render(<Environment />, document.getElementById("root"));

  if (
    process.env.REACT_APP_BUILD_TARGET === APP_CUSTOMER ||
    process.env.REACT_APP_BUILD_TARGET === APP_SALES
  ) {
    /**
     * ! Unregister service workers for now
     */
    serviceWorkerRegistration.unregister();
    // serviceWorkerRegistration.register({
    //   onSuccess: () => {
    //     notifications.notifyInfo(
    //       "This web app can be installed on the home screen and also works partially offline."
    //     );
    //   },
    //   onUpdate: () => {
    //     notifications.notifyInfo(
    //       "A new update is available. It will be used when all tabs of the app are closed."
    //     );
    //   },
    // });
  }
});
