import { Dependent } from "@deep-consulting-solutions/be2-constants";

export const findNextInvalidDependentId = (
  validMap: {
    [id: string]: boolean;
  },
  dependents: Dependent[],
  currentDependentId?: string
) => {
  // if (!currentDependentId) return null;

  const currentDependentIndex = dependents.findIndex(
    (dependent) => dependent.id === currentDependentId
  );

  let invalidDependentId = null;
  // eslint-disable-next-line no-plusplus
  for (let i = currentDependentIndex + 1; i < dependents.length; i++) {
    const dependentId = dependents[i].id;
    if (!validMap[dependentId]) {
      invalidDependentId = dependentId;
      break;
    }
  }
  return invalidDependentId;
};
