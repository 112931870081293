import {
  GetCustomerExistenceByEmail,
  GetValidateCustomerEmail,
  MainBeneficiary,
  PatchCheckoutEmployment,
} from "@deep-consulting-solutions/be2-constants";

import { getClient } from "apis";

export const findContactByEmailRequest = async (
  email: string
): Promise<MainBeneficiary | null> => {
  await new Promise((r) => setTimeout(() => r(email), 500));
  return null;
};

export const updateContact = async (
  data: typeof PatchCheckoutEmployment.Body & { id: string }
) => {
  const { id, ...body } = data;

  const res = await getClient().patch<typeof PatchCheckoutEmployment.Res>(
    PatchCheckoutEmployment.path.replace(":subscriptionId", id),
    body
  );

  return res.data.data;
};

export const checkIfEmailBelongsToClaimedContactReq = async (email: string) => {
  const res = await getClient().get<typeof GetCustomerExistenceByEmail.Res>(
    `${GetCustomerExistenceByEmail.ROUTE}?email=${email}`
  );

  return res.data.data;
};

export const checkIsClaimedOrHasActiveSubscriptionReq = async (
  email: string
) => {
  const res = await getClient().get<typeof GetValidateCustomerEmail.Res>(
    `${GetValidateCustomerEmail.ROUTE}?email=${email}`
  );

  return res.data.data;
};
