import {
  GetCustomerRefunds,
  GetContactCreditCards,
  PostContactCreditCard,
  PatchContactCreditCard,
  DeleteContactCreditCard,
  PostVerifyAddCardPayment,
  GetCustomerCreditCardsFromCRM,
  PatchCustomerCreditCardFromCRM,
  CreateCustomerCreditCardFromCRM,
  DeleteCustomerCreditCardFromCRM,
  PostVerifyAddCardPaymentFromCRM,
  PostInviteSubscriptionDependentToPortal,
  PostReInviteSubscriptionDependentToPortal,
} from "@deep-consulting-solutions/be2-constants";
import {
  sendInviteRes,
  sendPortalInvite,
} from "@deep-consulting-solutions/portals-constants";

import { getClient } from "apis";

export const fetchCreditCardReq = async () => {
  const res = await getClient().get<typeof GetContactCreditCards.Res>(
    GetContactCreditCards.ROUTE
  );
  return res.data.data;
};

export const fetchCreditCardsCRMReq = async (contactId: string) => {
  const res = await getClient(true).get<
    typeof GetCustomerCreditCardsFromCRM.Res
  >(GetCustomerCreditCardsFromCRM.ROUTE.replace(":id", contactId));

  return res.data.data;
};

export const addCreditCardReq = async () => {
  const res = await getClient().post<typeof PostContactCreditCard.Res>(
    PostContactCreditCard.ROUTE
  );
  return res.data.data;
};

export const addCreditCardCRMReq = async (contactId: string) => {
  const res = await getClient(true).post<
    typeof CreateCustomerCreditCardFromCRM.Res
  >(CreateCustomerCreditCardFromCRM.ROUTE.replace(":id", contactId));

  return res.data.data;
};

export const delCreditCardReq = async (id: string) => {
  const res = await getClient().delete<typeof DeleteContactCreditCard.Res>(
    DeleteContactCreditCard.ROUTE.replace(":id", id)
  );
  return res.data.data;
};

export const delCreditCardCRMReq = async (
  contactId: string,
  cardId: string
) => {
  const res = await getClient(true).delete<
    typeof DeleteCustomerCreditCardFromCRM.Res
  >(
    DeleteCustomerCreditCardFromCRM.ROUTE.replace(":id", contactId).replace(
      ":cardId",
      cardId
    )
  );

  return res.data.data;
};

export const verifyCreditCardReq = async ({
  isCRM,
  ...body
}: {
  orderId: string;
  isCRM?: boolean;
  holderName: string;
  handlesRecurringPayments?: string;
}) => {
  const res = await getClient(isCRM).post<typeof PostVerifyAddCardPayment.Res>(
    (isCRM ? PostVerifyAddCardPaymentFromCRM : PostVerifyAddCardPayment).ROUTE,
    body
  );
  return res.data.data;
};

export const updateCreditCardReq = async (
  id: string,
  data: {
    handlesRecurringPayments: boolean;
  }
) => {
  const res = await getClient().patch<{
    handlesRecurringPayments: boolean;
  }>(PatchContactCreditCard.ROUTE.replace(":id", id), data);
  return res.data;
};

export const updateCreditCardCRMReq = async (
  contactId: string,
  cardId: string,
  data: {
    handlesRecurringPayments: boolean;
  }
) => {
  const res = await getClient(true).patch<
    typeof PatchCustomerCreditCardFromCRM.Res
  >(
    PatchCustomerCreditCardFromCRM.ROUTE.replace(":id", contactId).replace(
      ":cardId",
      cardId
    ),
    data
  );

  return res.data.data;
};

export const fetchRefundsReq = async () => {
  const res = await getClient().get<typeof GetCustomerRefunds.Res>(
    GetCustomerRefunds.ROUTE
  );

  return res;
};

export type Portal = "Customer";

export const sendDependentPortalInvite = async (
  contactId: string,
  portal: Portal,
  email: string,
  isCRM?: boolean
) => {
  const res = await getClient(isCRM).post<sendInviteRes>(
    sendPortalInvite(contactId, portal),
    { email }
  );
  return res.data.data;
};

export const sendDependentInvite = async (
  isInvited = false,
  dependentContactId: string,
  subId: string,
  email: string
) => {
  if (isInvited) {
    const res = await getClient().post<
      typeof PostReInviteSubscriptionDependentToPortal.Res
    >(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      PostReInviteSubscriptionDependentToPortal.ROUTE.replace(
        ":id",
        dependentContactId
      ),
      {
        subscriptionId: subId,
        email,
      }
    );
    return res.data.data;
  }
  const res = await getClient().post<
    typeof PostInviteSubscriptionDependentToPortal.Res
  >(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    PostInviteSubscriptionDependentToPortal.ROUTE.replace(
      ":id",
      dependentContactId
    ),
    {
      subscriptionId: subId,
      email,
    }
  );
  return res.data.data;
};
