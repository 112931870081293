import { pkAuthActions } from "@deep-consulting-solutions/auth-web";
import {
  GetAllSubscriptionData,
  SubscriptionWithDetails,
} from "@deep-consulting-solutions/be2-constants";
import {
  createAsyncThunk,
  createSlice,
  createAction,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState, AppDispatch, ContactSubscriptionsState } from "../types";
import * as contactSubscriptionsRequests from "./requests";
import { getPostLoginValidSubscriptions } from "./helpers";

const ENTITY = "contactSubscriptionsRequest";

const initialState: ContactSubscriptionsState = {
  asMainBeneficiary: [],
  asDependent: [],
  subscriptionAsMainBeneficiaryAllData: undefined,
};
const resetContactSubscriptions = createAction(
  `${ENTITY}/resetContactSubscriptions`
);

const fetchSubscriptions = createAsyncThunk<
  {
    asDependent: SubscriptionWithDetails[];
    asMainBeneficiary: SubscriptionWithDetails[];
  },
  undefined,
  { state: AppState; dispatch: AppDispatch }
>(`${ENTITY}/fetchSubscriptions`, async (__, { getState }) => {
  const { isAuthorised } = getState().pkAuth;

  if (isAuthorised) {
    const mainBeneficiarySubscriptions = await contactSubscriptionsRequests.fetchSubscriptionsAsMainBeneficiaryReq();
    const dependentSubscriptions = await contactSubscriptionsRequests.fetchSubscriptionsAsDependentReq();
    return {
      asMainBeneficiary: getPostLoginValidSubscriptions(
        mainBeneficiarySubscriptions
      ),
      asDependent: getPostLoginValidSubscriptions(dependentSubscriptions),
    };
  }

  return { asMainBeneficiary: [], asDependent: [] };
});

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {
    setSubscriptionAllData: (
      state,
      action: PayloadAction<typeof GetAllSubscriptionData.Res.data>
    ) => {
      state.subscriptionAsMainBeneficiaryAllData = action.payload;
    },
    removeSubscriptionAllData: (state) => {
      state.subscriptionAsMainBeneficiaryAllData = undefined;
    },
  },
  extraReducers: (builders) =>
    builders
      .addCase(pkAuthActions.logout.fulfilled, () => {
        return { ...initialState };
      })
      .addCase(resetContactSubscriptions, () => {
        return { ...initialState };
      })
      .addCase(fetchSubscriptions.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      }),
});

const getSubscriptionsAsMainBeneficiary = ({
  contactSubscriptions: { asMainBeneficiary },
}: AppState) => asMainBeneficiary;

const getSubscriptionsAsDependent = ({
  contactSubscriptions: { asDependent },
}: AppState) => asDependent;

const getSubscriptionAsMainBeneficiaryAllData = ({
  contactSubscriptions: { subscriptionAsMainBeneficiaryAllData },
}: AppState) => subscriptionAsMainBeneficiaryAllData;

export const contactSubscriptionsSelectors = {
  getSubscriptionsAsMainBeneficiary,
  getSubscriptionsAsDependent,
  getSubscriptionAsMainBeneficiaryAllData,
};

export const contactSubscriptionsActions = {
  ...slice.actions,
  fetchSubscriptions,
};

export { contactSubscriptionsRequests };

export default slice.reducer;
