/**
 * @description Persist email resend data to avoid multiple requests errors
 */

const storageKey = "$BE2-$EMAIL-$OTP-$STORAGE-$KEY";

const get = () => {
  const raw = sessionStorage.getItem(storageKey);
  return JSON.parse(raw ?? "{}") as Record<string, number>;
};

const create = (email: string, milliSeconds: number) => {
  const sentEmailsAndExpiryTime = get();
  sentEmailsAndExpiryTime[email] = milliSeconds;
  sessionStorage.setItem(storageKey, JSON.stringify(sentEmailsAndExpiryTime));
};

const findOne = (email: string) => {
  const sentEmailsAndExpiryTime = get();
  return sentEmailsAndExpiryTime[email];
};

export const emailOtpPersistService = {
  create,
  findOne,
};
