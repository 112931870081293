import {
  isAnyOf,
  createSlice,
  PayloadAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { CreditCard } from "@deep-consulting-solutions/be2-constants";
import { differenceInMonths } from "date-fns";
import { pkAuthActions } from "@deep-consulting-solutions/auth-web";
import {
  fetchRefundsReq,
  addCreditCardReq,
  delCreditCardReq,
  fetchCreditCardReq,
  addCreditCardCRMReq,
  updateCreditCardReq,
  delCreditCardCRMReq,
  fetchCreditCardsCRMReq,
  updateCreditCardCRMReq,
} from "./requests";
import type { AppDispatch, AppState } from "../types";

const ENTITY = "dashboardState";

type DashboardViewType = { isFromHome: boolean; isMain?: boolean };

type InitType = {
  view: DashboardViewType;
  profileNotice: Record<string, boolean>;
  creditCards: {
    cards: CreditCard[];
    selected: number;
    errors: { type: "error" | "warning"; message: string }[];
  };
  linkedFromMobile: boolean;
};

const initCard = (cards: CreditCard[]) => {
  const errors: any[] = [];

  const defaultPos = cards.findIndex((el) => el.handlesRecurringPayments);
  if (defaultPos > -1) {
    const el = cards[defaultPos];
    if (el.handlesRecurringPayments) {
      const result = differenceInMonths(
        new Date(el.expiryYear, el.expiryMonth - 1, 1),
        new Date()
      );

      if (result >= 0) {
        if (result <= 1) {
          errors.push({
            type: "warning",
            message: `The Credit Card used for recurring payment will expire soon! \nPlease update or add a new Credit Card.`,
          });
        } else if (result <= 2) {
          errors.push({
            type: "warning",
            message: `The Credit Card used for recurring payment will expire in 2 months! \nPlease update or add a new Credit Card.`,
          });
        }
      } else if (result < 0) {
        errors.push({
          type: "error",
          message:
            "The Credit Card used for recurring payment has expired! Please use a different card.",
        });
      }
    }
  }
  return {
    cards,
    selected: defaultPos > -1 ? defaultPos : 0,
    errors,
  };
};

const fetchCreditCards = createAsyncThunk<
  { cards: CreditCard[]; selected: number; errors: any[] },
  { isCRM?: boolean; contactId: string }
>(`${ENTITY}/fetchCreditCards`, async ({ isCRM, contactId }) => {
  if (isCRM) {
    const res = await fetchCreditCardsCRMReq(contactId);
    return initCard(res);
  }

  const res = await fetchCreditCardReq();
  return initCard(res);
});

const createCreditCard = createAsyncThunk<
  string,
  {
    isCRM?: boolean;
    contactId: string;
  }
>(`${ENTITY}/createCreditCards`, async ({ isCRM, contactId }) => {
  if (isCRM) {
    const res = await addCreditCardCRMReq(contactId);
    return res.orderId;
  }

  const res = await addCreditCardReq();
  return res.orderId;
});

const deleteCreditCard = createAsyncThunk<
  CreditCard[],
  {
    cardId: string;
    isCRM?: boolean;
    contactId: string;
  },
  {
    state: AppState;
    dispatch: AppDispatch;
  }
>(
  `${ENTITY}/delCreditCard`,
  async ({ isCRM, cardId, contactId }, { getState }) => {
    if (isCRM) {
      await delCreditCardCRMReq(contactId, cardId);
    } else {
      await delCreditCardReq(cardId);
    }

    const { dashboardState } = getState();

    return dashboardState.creditCards.cards.filter(
      (el: { id: string }) => el.id !== cardId
    );
  }
);

const updateCreditCard = createAsyncThunk<
  InitType["creditCards"],
  {
    isCRM?: boolean;
    contactId: string;
    cardId: string;
    data: {
      handlesRecurringPayments: boolean;
    };
  },
  {
    state: AppState;
  }
>(
  `${ENTITY}/updateCreditCard`,
  async ({ data, isCRM, cardId, contactId }, { getState }) => {
    const dashboardState = getState().dashboardState;

    if (isCRM) {
      await updateCreditCardCRMReq(contactId, cardId, data);
    } else {
      await updateCreditCardReq(cardId, data);
    }

    // remove previous recurring card and set new recurring card
    const updateCards = dashboardState.creditCards.cards.map((el) => {
      if (el.handlesRecurringPayments) {
        return { ...el, handlesRecurringPayments: false };
      }

      if (el.id === cardId) {
        return { ...el, handlesRecurringPayments: true };
      }

      return el;
    });

    return initCard(updateCards);
  }
);

const fetchRefunds = createAsyncThunk(`${ENTITY}/getRefunds`, async () => {
  const res = await fetchRefundsReq();
  return res;
});

const initialState: InitType = {
  view: { isFromHome: false },
  profileNotice: {},
  creditCards: {
    cards: [],
    selected: 0,
    errors: [],
  },
  linkedFromMobile: false,
};

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {
    dashboardViewer: (state, action: PayloadAction<DashboardViewType>) => {
      state.view = { ...state.view, ...action.payload };
    },
    updateProfileNotice: (
      state,
      action: PayloadAction<{ label: string; show: boolean }>
    ) => {
      state.profileNotice[action.payload.label] = action.payload.show;
    },
    updateCreditCardList: (state, action: PayloadAction<CreditCard[]>) => {
      state.creditCards.cards = action.payload;
    },
    removeError: (state) => {
      state.creditCards.errors = [];
    },
    setSelected: (state, action: PayloadAction<number>) => {
      state.creditCards.selected = action.payload;
    },
    setRoutedFromMobile: (state, action: PayloadAction<boolean>) => {
      state.linkedFromMobile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pkAuthActions.logout.fulfilled, (state) => {
      state.view = { isFromHome: false };
      state.profileNotice = {};
      state.creditCards = {
        cards: [],
        selected: 0,
        errors: [],
      };
    });
    builder.addCase(deleteCreditCard.fulfilled, (state, action) => {
      state.creditCards.cards = action.payload;
    });
    builder.addMatcher(
      isAnyOf(fetchCreditCards.fulfilled, updateCreditCard.fulfilled),
      (state, action) => {
        state.creditCards = action.payload;
      }
    );
  },
});

export const dashboardAction = {
  ...slice.actions,
  fetchCreditCards,
  createCreditCard,
  updateCreditCard,
  deleteCreditCard,
  fetchRefunds,
};

const getCreditCards = (state: AppState) => state.dashboardState.creditCards;

export const dashboardSelectors = {
  getCreditCards,
  isRoutedFromMobile: (state: AppState) =>
    state.dashboardState.linkedFromMobile,
};

export default slice.reducer;
