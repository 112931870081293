import Dexie, { Table } from "dexie";

// declare the type of values to be stored in the database.
interface DBFile {
  id?: string;
  file: File;
}

export class FilesDexie extends Dexie {
  // 'files' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  files!: Table<DBFile>;

  constructor() {
    super("filesDatabase"); // the name of our database.
    this.version(1).stores({
      files: "++id, file", // primary key and indexed props
    });
  }
}

export const db = new FilesDexie();
