import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const completeMissingInfo = createSlice({
  name: "completeInfo",
  initialState: {
    current: 0,
    forms: {
      main: {} as Record<string, any>,
      dependents: {} as Record<string, any>,
      docs: {} as Record<string, any>,
    },
  },
  reducers: {
    reset: (state) => {
      state.current = 0;
      state.forms = {
        main: {},
        dependents: {},
        docs: {},
      };
    },
    updateNotice: (
      state,
      action: PayloadAction<{
        id: "main" | "dependents" | "docs";
        label: string;
        value: { error: boolean; values: Record<string, any> };
      }>
    ) => {
      const { id, label, value } = action.payload;
      try {
        state.forms[id] = { ...state.forms[id], [label]: value };
      } catch (e) {
        state.forms = { ...state.forms, [id]: { [label]: value } };
      }
    },
    goNext: (state, action: PayloadAction<{ next: number; total: number }>) => {
      const next = state.current + action.payload.next;
      if (next < 0) {
        state.current = 0;
      } else if (next > action.payload.total) {
        state.current = action.payload.total;
      } else {
        state.current = next;
      }
    },
  },
});

export const { goNext, reset, updateNotice } = completeMissingInfo.actions;

export default completeMissingInfo.reducer;
