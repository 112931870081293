import { Origin } from "@deep-consulting-solutions/be2-constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AppState, OriginOfInterestsState } from "../types";
import * as originOfInterestsRequests from "./requests";

const ENTITY = "originOfInterests";

const initialState: OriginOfInterestsState = {
  origins: [],
};

const fetchOriginOfInterests = createAsyncThunk<{ origins: Origin[] }>(
  `${ENTITY}/fetchOriginOfInterests`,
  async () => {
    const origins = await originOfInterestsRequests.fetchOriginsOfInterestsReq();
    return { origins };
  }
);

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {},
  extraReducers: (builders) =>
    builders.addCase(fetchOriginOfInterests.fulfilled, (state, action) => {
      state.origins = action.payload.origins;
    }),
});

const getOrigins = ({ originOfInterests: { origins } }: AppState) => origins;

export const originOfInterestsSelectors = {
  getOrigins,
};

export const originOfInterestsActions = {
  ...slice.actions,
  fetchOriginOfInterests,
};

export { originOfInterestsRequests };

export default slice.reducer;
