import {
  isValid,
  differenceInMinutes,
  format,
  differenceInCalendarYears,
  addMonths,
} from "date-fns";
import {
  DATE_DISPLAY_FORMAT,
  DATE_REQUEST_FORMAT,
  DATE_TIME_REQUEST_FORMAT,
} from "configs";

export const getDifferenceInMinutes = (start: string, end: string) => {
  const s = new Date(start);
  const e = new Date(end);

  if (!isValid(s) || !isValid(e)) {
    return {
      minutes: 0,
      string: "0 minute",
    };
  }

  const diff = differenceInMinutes(e, s);
  const unit = diff > 1 ? "minutes" : "minute";
  return {
    minutes: diff,
    string: `${diff} ${unit}`,
  };
};

export const formatDateToDisplay = (d: string | Date, dateFormat?: string) => {
  const date = typeof d === "string" ? new Date(d) : d;
  return format(date, dateFormat || DATE_DISPLAY_FORMAT);
};

export const formatDateToRequest = (d: string | Date) => {
  const date = typeof d === "string" ? new Date(d) : d;
  return format(date, DATE_REQUEST_FORMAT);
};

export const formatDateToRequestInUTC = (
  d: string | Date,
  withTime?: boolean
) => {
  const date = typeof d === "string" ? new Date(d) : d;
  const utcDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000
  );
  if (!withTime) return formatDateToRequest(utcDate);
  return format(utcDate, DATE_TIME_REQUEST_FORMAT);
};

export const formatTimeToDisplay = (d: string | Date, am?: boolean) => {
  const date = typeof d === "string" ? new Date(d) : d;
  return format(date, am ? "KK:mm a" : "HH:mm");
};

export const getAgeFromDOB = (dob: string) => {
  return differenceInCalendarYears(new Date(), new Date(dob));
};

export const getFormattedDateFromNow = (monthsFromNow = 0) => {
  let date = new Date();
  if (monthsFromNow) {
    date = addMonths(date, monthsFromNow);
  }
  return formatDateToRequest(date);
};

export const addWorkDaysToDate = (date: Date, days: number) => {
  const day = date.getDay();
  let daysToAdd = Math.floor(days);

  // For sunday
  if (day === 0) {
    daysToAdd += 1;
  }

  // For Saturday
  if (day + daysToAdd >= 6) {
    const remainingWorkDays = daysToAdd - (5 - day);
    daysToAdd += 2; // add weekend
    if (remainingWorkDays > 5) {
      // Add two days for each working week
      daysToAdd += 2 * Math.floor(remainingWorkDays / 5);

      // Remove the final weekend
      if (remainingWorkDays % 5 === 0) daysToAdd -= 2;
    }
  }
  date.setDate(date.getDate() + daysToAdd);
  return date;
};

export const getTomorrow = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  return tomorrow;
};
