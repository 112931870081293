import { CardBrandEnum } from "@deep-consulting-solutions/be2-constants";
import { fns } from "payment";
import * as Yup from "yup";
import { getENText } from "./languages";

const clearNumber = (value = "") => {
  return value.replace(/\D+/g, "");
};

export interface Values {
  cvv: string;
  holderName: string;
  expiryDate: string;
  cardNumber: string;
}

export const getCardIssuer = (value: string) => fns.cardType(value);

export const formatExpirationDate = (value: string) => {
  const hasLeadingZero = value && value[0] === "0";
  const clearValue = String(parseInt(clearNumber(value), 10));

  if (clearValue.length > 3) {
    if (parseInt(clearValue.slice(0, 4), 10) > 1299) {
      return `0${clearValue.slice(0, 1)}/${clearValue.slice(1, 3)}`;
    }

    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  if (clearValue.length === 3) {
    if (parseInt(clearValue, 10) > 129) {
      return `${clearValue.slice(0, 1)}/${clearValue.slice(1, 3)}`;
    }
    if (hasLeadingZero) {
      return `0${clearValue.slice(0, 1)}/${clearValue.slice(1, 3)}`;
    }
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 3)}`;
  }

  if (clearValue.length === 2) {
    if (parseInt(clearValue, 10) > 12) {
      return `0${clearValue.slice(0, 1)}/${clearValue.slice(1, 3)}`;
    }
    if (hasLeadingZero) {
      return `0${clearValue.slice(0, 1)}/${clearValue.slice(1, 3)}`;
    }
    return `${clearValue}/`;
  }

  if (clearValue.length === 1) {
    if (parseInt(clearValue, 10) > 1) {
      return `0${clearValue}/`;
    }
    if (parseInt(clearValue, 10) === 0) {
      return clearValue;
    }
    if (hasLeadingZero) {
      return `0${clearValue}/`;
    }

    return clearValue;
  }

  return clearValue;
};

export const formatCVV = (
  value: string,
  previousValue: string,
  values: Values
) => {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (values?.cardNumber) {
    const issuer = getCardIssuer(values?.cardNumber);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
};

export const formatCreditCardNumber = (value: string) => {
  if (!value) {
    return value;
  }
  return fns.formatCardNumber(value);
};

export const getLastFourDigits = (value: number) =>
  value
    .toString()
    .split("")
    .reverse()
    .join("")
    .substring(0, 4)
    .split("")
    .reverse()
    .join("");

export const getValInExpiryFormat = (val?: string | null): string => {
  if (!val) return "";
  const reversedString = val
    .replace("/", "")
    .toString()
    .split("")
    .reverse()
    .join("");
  const formatReversedString = `${reversedString.slice(
    0,
    2
  )}/${reversedString.slice(2)}`;

  return formatReversedString.split("").reverse().join("");
};

export const getMonthAndYear = (value: string) => {
  const monthAndYear = fns.cardExpiryVal(getValInExpiryFormat(value));
  return {
    expiryMonth: monthAndYear.month,
    expiryYear: monthAndYear.year,
  };
};

export const creditCardValidationSchema = () => ({
  cvv: Yup.string()
    .required(getENText("validation.cvv.not.valid"))
    .test({
      name: "cvv",
      message: getENText("validation.cvv.not.valid"),
      test(val) {
        try {
          const cardNumber = this.parent?.cardNumber;
          const cardType = fns.cardType(cardNumber);
          return !!val && fns.validateCardCVC(val, cardType);
        } catch {
          return false;
        }
      },
    }),
  holderName: Yup.string().required(
    getENText("validation.holderName.required")
  ),
  expiryDate: Yup.string()
    .required(getENText("validation.expiryDate.not.valid"))
    .test("expiryDate", getENText("validation.expiryDate.not.valid"), (val) => {
      return !!val && fns.validateCardExpiry(getValInExpiryFormat(val));
    }),
  cardNumber: Yup.string()
    .required(getENText("validation.cardNumber.not.valid"))
    .test(
      "cardNumber",
      getENText("validation.cardNumber.not.valid"),
      (val) => !!val && fns.validateCardNumber(val)
    ),
});

export const getCardBrandEnumFromCardBrand = (
  brand: string
): CardBrandEnum | undefined =>
  Object.values(CardBrandEnum).find(
    (cardBrandEnum) => cardBrandEnum === brand.toUpperCase()
  );

export const formatExpiryNumber = (expiryDate: string | undefined | null) => {
  if (!expiryDate) {
    return "";
  }
  const expiryDateString = expiryDate.replace("/", "");
  if (expiryDateString.length === 3) {
    return `0${expiryDateString}`;
  }
  return expiryDateString;
};
