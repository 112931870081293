import {
  SubscriptionWithDetails,
  SubscriptionStatusEnum,
} from "@deep-consulting-solutions/be2-constants";

export const getPostLoginValidSubscriptions = (
  subscriptions: SubscriptionWithDetails[]
) =>
  subscriptions.filter((subscription) =>
    [
      SubscriptionStatusEnum.PENDING_COMPLETION,
      SubscriptionStatusEnum.PENDING_VALIDATION,
      SubscriptionStatusEnum.VALIDATING,
      SubscriptionStatusEnum.PENDING_ACTIVATION,
      SubscriptionStatusEnum.ACTIVE,
      SubscriptionStatusEnum.SUSPENDED,
      SubscriptionStatusEnum.VALID,
      SubscriptionStatusEnum.REJECTED,
    ].includes(subscription.status)
  );
