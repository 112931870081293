import {
  Plan,
  PlanToSlugMap,
  SubscriptionPlanEnum,
  SubscriptionPlanRateTypeEnum,
} from "@deep-consulting-solutions/be2-constants";
import { OfferCardPrice } from "components/OfferCard";

export const PLAN_ENUM_TO_SLUG = (() => {
  const map = Object.entries(SubscriptionPlanEnum).reduce(
    (obj: { [plan: string]: string }, [key, value]) => {
      return {
        ...obj,
        [value]: (PlanToSlugMap as any)[key] as string,
      };
    },
    {}
  );
  return map as { [planEnum in SubscriptionPlanEnum]: string };
})();

export const convertPlansToSlugs = (plans: SubscriptionPlanEnum[]) => {
  return plans.map((plan) => PLAN_ENUM_TO_SLUG[plan]);
};

export const convertPlansToOfferCardPrices = (plans: Plan[]) => {
  const pricing = {
    currency: "BSD",
    period: "/mo*",
  };

  return plans.reduce((acc, plan) => {
    const offerCardPrices = [
      {
        ...pricing,
        isPlaceholder: !plan.currentPlanVersion.summary.individualRate,
        price:
          plan.currentPlanVersion.summary.individualRate?.baseMonthlyFee || 0,
        rate: SubscriptionPlanRateTypeEnum.INDIVIDUAL,
      },
      {
        ...pricing,
        isPlaceholder: !plan.currentPlanVersion.summary.familyRate,
        price: plan.currentPlanVersion.summary.familyRate?.baseMonthlyFee || 0,
        rate: SubscriptionPlanRateTypeEnum.FAMILY,
      },
    ];

    acc[plan.name] = offerCardPrices.sort(
      (a, b) => Number(a.isPlaceholder) - Number(b.isPlaceholder)
    );
    return acc;
  }, {} as Record<SubscriptionPlanEnum, OfferCardPrice[]>);
};
