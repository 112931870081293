import { GetSubscriptionPlans } from "@deep-consulting-solutions/be2-constants";

import { getClient } from "apis";

export const fetchSubscriptionsReq = async () => {
  const res = await getClient().get<typeof GetSubscriptionPlans.Res>(
    GetSubscriptionPlans.ROUTE
  );

  return res.data.data;
};
