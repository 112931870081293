import { GetHospitals } from "@deep-consulting-solutions/be2-constants";
import { getClient } from "apis";

export const fetchHospitalsReq = async () => {
  const res = await getClient().get<typeof GetHospitals.Res>(
    GetHospitals.ROUTE
  );

  return res.data.data;
};
