export const COLORS = {
  darkHeader: "#0D151F",
  primaryDarkGreyed: "#2C344A",
  blueCardGradient:
    "linear-gradient(36.33deg, #5294EF 0.78%, rgba(255, 255, 255, 0) 60.41%, rgba(72, 149, 254, 0.37) 91.65%)",
  goldCardGradient:
    "linear-gradient(35.48deg, rgba(202, 152, 19, 0.78) 0%, rgba(243, 191, 4, 0.4) 32.94%, rgba(255, 255, 255, 0) 52.16%, #FFE871 71.83%, #F3B438 87.84%)",
  platinumCardGradient:
    "linear-gradient(37.8deg, rgba(86, 86, 86, 0.5) 0%, rgba(232, 232, 232, 0.260417) 41.24%, rgba(53, 53, 53, 0.46) 86.06%)",
  redCardGradient:
    "linear-gradient(48.46deg, rgba(159, 42, 32, 0.5) 0%, rgba(208, 101, 92, 0) 46.94%, rgba(255, 21, 1, 0.19) 80.46%)",
  blueDividerGradient:
    "radial-gradient(49.25% 1970000.03% at 50% 100.04%, #263E7F 0%, #D5DCE3 100%)",
  goldDividerGradient:
    "radial-gradient(49.25% 1970000.03% at 50% 100.04%, #FBBC05 0%, #FFFFFF 100%)",
  platinumDividerGradient:
    "radial-gradient(49.25% 1970000.03% at 50% 100.04%, #BDBDBD 0%, #FFFFFF 100%)",
  redDividerGradient:
    "radial-gradient(49.25% 1970000.03% at 50% 100.04%, #EA4335 0%, #FFFFFF 100%)",
  greyDividerGradient:
    "radial-gradient(49.25% 1970000.03% at 50% 100.04%, #E0E0E0 0%, rgba(213, 220, 227, 0) 100%)",
  blueCardBottom: "linear-gradient(0, #5294EF, white 25%)",
  redCardBottom: "linear-gradient(0, rgba(159, 42, 32, 0.5), white 25%)",
  greenBadge: "#27AE60",
  orangeBadge: "#F2994A",
};
