import axios from "axios";
import { LocationInfo } from "@deep-consulting-solutions/be2-constants";

import { notifications } from "services";
import { getENText } from "helpers";

interface JSONLocationInfo {
  ip: string;
  city: string;
  flagUrl: string;
  currency: string;
  latitude: number;
  zip_code: string;
  emojiFlag: string;
  longitude: number;
  time_zone: string;
  metro_code: number;
  callingCode: string;
  region_code: string;
  region_name: string;
  country_code: string;
  country_name: string;
  organisation: string;
  countryCapital: string;
  currencySymbol: string;
  is_in_european_union: boolean;
  suspiciousFactors: {
    isSpam: boolean;
    isProxy: boolean;
    isTorNode: boolean;
    isSuspicious: boolean;
  };
}

export const getLocationInfo = async () => {
  try {
    const res = await axios.get<JSONLocationInfo>("https://ip-api.io/json");
    const { latitude, longitude, ip, country_name: country } = res.data;

    const info: LocationInfo = {
      ip,
      country,
      latitude: `${latitude}`,
      longitude: `${longitude}`,
    };

    return info;
  } catch {
    const message = getENText("location.error");
    notifications.notifyError(message);
    throw new Error(message);
  }
};

export const ddToDMS = (lat: number, lng: number) => {
  const latDeg = Math.floor(lat);
  const latMin = Math.floor((lat - latDeg) * 60);
  const latSec = Math.floor(((lat - latDeg) * 60 - latMin) * 60);
  const lngDeg = Math.floor(lng);
  const lngMin = Math.floor((lng - lngDeg) * 60);
  const lngSec = Math.floor(((lng - lngDeg) * 60 - lngMin) * 60);
  return `${latDeg}° ${latMin}' ${latSec}" N, ${lngDeg}° ${lngMin}' ${lngSec}" W`;
};

export const geoLocateMe = () => {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    if ("geolocation" in navigator) {
      return navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true,
        timeout: 10000,
      });
    }
    return reject(new Error("UNSUPPORTED"));
  });
};

export type PositionErrorType =
  | "UNSUPPORTED"
  | "PERMISSION_DENIED"
  | "POSITION_UNAVAILABLE"
  | "TIMEOUT";

type ReturnValue = Promise<
  | {
      position: GeolocationPosition;
      error?: undefined;
    }
  | {
      error: {
        code: PositionErrorType;
      };
      position?: undefined;
    }
>;

export const getLocation = async (tries = 0): Promise<ReturnValue> => {
  try {
    const position = await geoLocateMe();
    return { position };
  } catch (error: any) {
    if (tries < 3) {
      return getLocation(tries + 1);
    }
    let code: PositionErrorType = "UNSUPPORTED";
    if (error.code === 1) {
      code = "PERMISSION_DENIED";
    } else if (error.code === 2) {
      code = "POSITION_UNAVAILABLE";
    } else if (error.code === 3) {
      code = "TIMEOUT";
    }
    return {
      error: { code },
    };
  }
};

export function getLongAddressObject(object: google.maps.GeocoderResult) {
  const address: { [key: string]: string } = {};
  const addressComponents = object.address_components;
  addressComponents.forEach((element) => {
    address[element.types[0]] = element.long_name;
    if (element.types.includes("sublocality")) {
      address.sublocality = element.long_name;
    }
  });
  return address;
}

export const getLocationFromGeoCodeResult = (
  result: google.maps.GeocoderResult
) => {
  const latLng = result.geometry.location;
  const longAddress = getLongAddressObject(result);
  const lat = parseFloat(latLng.lat().toFixed(7));
  const lng = parseFloat(latLng.lng().toFixed(7));
  const dms = ddToDMS(lat, lng);

  const street = `${longAddress.street_number || ""}${
    longAddress.route
      ? `${longAddress.street_number ? " " : ""}${longAddress.route}`
      : ""
  }`;

  return {
    lat,
    lng,
    dms,
    address: street || "Unnamed road",
    plusCode: result.plus_code?.compound_code,
  };
};
