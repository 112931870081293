import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AppState, CitizenshipsState } from "../types";
import * as citizenshipsRequests from "./requests";

const ENTITY = "citizenships";

const initialState: CitizenshipsState = {
  citizenships: [],
};

const fetchCitizenships = createAsyncThunk<{ citizenships: string[] }>(
  `${ENTITY}/fetchCitizenships`,
  async () => {
    const citizenships = await citizenshipsRequests.fetchCitizenshipsReq();
    return { citizenships };
  }
);

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {},
  extraReducers: (builders) =>
    builders.addCase(fetchCitizenships.fulfilled, (state, action) => {
      state.citizenships = action.payload.citizenships;
    }),
});

const getCitizenships = ({ citizenships: { citizenships } }: AppState) =>
  citizenships;

export const citizenshipsSelectors = {
  getCitizenships,
};

export const citizenshipsActions = {
  ...slice.actions,
  fetchCitizenships,
};

export { citizenshipsRequests };

export default slice.reducer;
