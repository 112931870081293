import { getClient } from "apis";
import {
  GetAllSubscriptionData,
  GetSubscriptions,
} from "@deep-consulting-solutions/be2-constants";

export const fetchSubscriptionsAsMainBeneficiaryReq = async () => {
  const res = await getClient().get<typeof GetSubscriptions.Res>(
    "/subscriptions/main-beneficiary"
  );
  return res.data.data;
};

export const fetchSubscriptionsAsDependentReq = async () => {
  const res = await getClient().get<typeof GetSubscriptions.Res>(
    "/subscriptions/beneficiary"
  );
  return res.data.data;
};

export const fetchAllSubscriptionDataReq = async (subscriptionId: string) => {
  const res = await getClient().get<typeof GetAllSubscriptionData.Res>(
    GetAllSubscriptionData.ROUTE.replace(":subscriptionId", subscriptionId)
  );
  return res.data.data;
};
