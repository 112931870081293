import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
  DeepPartial,
} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  pkAuthReducerObj,
  pkAuthPersistTransform,
} from "@deep-consulting-solutions/auth-web";

import { getPersistKey } from "helpers";
import {
  pkPortalsPersistTransform,
  pkPortalsReducerObj,
} from "@deep-consulting-solutions/portals-web";
import { APP_NAME, APP_VERSION } from "AppCustomer/configs";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import subRequest from "./subRequest";
import subscriptionPlans from "./subscriptionPlans";
import originOfInterests from "./originOfInterests";
import hospitals from "./hospitals";
import citizenships from "./citizenships";
import dashboardState from "./dashboardView";
import subRequestPostAuthChecks from "./subRequestPostAuthChecks";
import contactSubscriptions from "./contactSubscriptions";
import dashboardRefreshConditions from "./dashboardRefreshConditions";
import completeInfo from "./completeMissingInfo";
import { AppState } from "./types";

const persistConfigs = {
  key: getPersistKey(APP_NAME, APP_VERSION),
  storage,
  blacklist: [
    "subscriptionPlans",
    "hospitals",
    "citizenships",
    "subRequestPostAuthChecks",
    "dashboardRefreshConditions",
  ],
  transforms: [pkAuthPersistTransform, pkPortalsPersistTransform],
};

export const createStore = (initialState?: DeepPartial<AppState>) => {
  const reducer = combineReducers({
    ...pkAuthReducerObj,
    ...pkPortalsReducerObj,
    subRequest,
    subscriptionPlans,
    originOfInterests,
    hospitals,
    citizenships,
    contactSubscriptions,
    completeInfo,
    dashboardState,
    subRequestPostAuthChecks,
    dashboardRefreshConditions,
  });

  const persistedReducer = persistReducer(persistConfigs, reducer);

  return configureStore({
    reducer: persistedReducer,
    preloadedState: initialState || {},
    middleware: getDefaultMiddleware({
      serializableCheck: false,
    }),
  });
};

const store = createStore();
type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default store;
