import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppState } from "../types";

const ENTITY = "subRequestPostAuthChecks";

export interface SubRequestPostAuthChecks {
  isChecked: boolean;
}

const initialState: SubRequestPostAuthChecks = {
  isChecked: false,
};

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {
    updateIsChecked: (state, action: PayloadAction<{ isChecked: boolean }>) => {
      state.isChecked = action.payload.isChecked;
    },
  },
});

export const subRequestPostAuthChecksAction = {
  ...slice.actions,
};

const getIsChecked = (state: AppState) =>
  state.subRequestPostAuthChecks.isChecked;

export const subRequestPostAuthChecksSelectors = {
  getIsChecked,
};

export default slice.reducer;
