import axios, { AxiosError } from "axios";
import { getENText } from "helpers";
import {
  pkAuthActions,
  ENDPOINT_AUTH,
  ENDPOINT_AUTH_LOGOUT,
} from "@deep-consulting-solutions/auth-web";

import store from "AppCustomer/redux/store";
import { notifications } from "services";
import { TOKEN_EXPIRED_BE_ERROR } from "configs";
import { subRequestsActions } from "AppCustomer/redux/subRequest";

export const createClient = (options?: {
  baseUrl?: string;
  disableActionsOnError?: boolean;
}) => {
  const client = axios.create({
    baseURL: options?.baseUrl ?? process.env.REACT_APP_BASE_URL,
  });
  const silentAxiosClient = axios.create({
    baseURL: options?.baseUrl ?? process.env.REACT_APP_BASE_URL,
  });

  const setAuthorizationHeader = (token: string) => {
    client.defaults.headers.Authorization = `Bearer ${token}`;
    silentAxiosClient.defaults.headers.Authorization = `Bearer ${token}`;
  };

  const getAuthorizationToken = (): string | undefined => {
    return (client.defaults.headers.Authorization as string | null)?.replace(
      "Bearer ",
      ""
    );
  };

  const removeAuthorizationHeader = () => {
    delete client.defaults.headers.Authorization;
    delete silentAxiosClient.defaults.headers.Authorization;
  };

  // Add a response interceptor
  client.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      let message =
        error?.response?.data?.message || error?.message || "Unknown error";

      if (typeof message !== "string") {
        try {
          message = JSON.stringify(message);
        } catch {
          message = "Unknown error";
        }
      }

      if (!options || !options.disableActionsOnError) {
        const originalRequest = error.config;
        if (
          error.response?.status === 401 &&
          originalRequest.url !== ENDPOINT_AUTH
        ) {
          if (message === TOKEN_EXPIRED_BE_ERROR) {
            notifications.notifyInfo(getENText("api.noti.info.tokenExpired"), {
              preventDuplicate: true,
            });

            store.dispatch(subRequestsActions.resetSubRequest());
          } else {
            notifications.notifyError(message, { preventDuplicate: true });
          }

          await store.dispatch(pkAuthActions.logout());
          store.dispatch(subRequestsActions.tokenExpired());
          return Promise.reject(error);
        }
      }

      notifications.notifyError(message, {
        persist: [408, 500, 501, 502, 503, 504].includes(
          error.response?.status as number
        ),
      });

      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  silentAxiosClient.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    async (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      const originalRequest = error.config;
      if (
        error.response?.status === 401 &&
        originalRequest.url !== ENDPOINT_AUTH_LOGOUT
      ) {
        await store.dispatch(pkAuthActions.logout());
      }

      return Promise.reject(error);
    }
  );

  return {
    client,
    silentClient: silentAxiosClient,
    setAuthorizationHeader,
    removeAuthorizationHeader,
    getAuthorizationToken,
  };
};

export const {
  client: apiClient,
  silentClient,
  removeAuthorizationHeader,
  setAuthorizationHeader,
  getAuthorizationToken,
} = createClient();

export const {
  client: crmClient,
  silentClient: crmSilentClient,
  removeAuthorizationHeader: removeCRMAuthHeader,
  setAuthorizationHeader: setCRMAuthHeader,
} = createClient({ disableActionsOnError: true });

export const getClient = (isCRM?: boolean) => {
  return isCRM ? crmClient : apiClient;
};
