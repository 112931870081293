import { Plan } from "@deep-consulting-solutions/be2-constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AppState, SubscriptionPlansState } from "../types";
import * as subscriptionPlansRequests from "./requests";

const ENTITY = "subscriptionPlans";

const initialState: SubscriptionPlansState = {
  plans: [],
};

const fetchSubscriptionPlans = createAsyncThunk<{ plans: Plan[] }>(
  `${ENTITY}/fetchSubscriptionPlans`,
  async () => {
    const plans = await subscriptionPlansRequests.fetchSubscriptionsReq();
    return { plans };
  }
);

const slice = createSlice({
  name: ENTITY,
  initialState,
  reducers: {},
  extraReducers: (builders) =>
    builders.addCase(fetchSubscriptionPlans.fulfilled, (state, action) => {
      state.plans = action.payload.plans;
    }),
});

const getPlans = ({ subscriptionPlans: { plans } }: AppState) => plans;

export const subscriptionPlansSelectors = {
  getPlans,
};

export const subscriptionPlansActions = {
  ...slice.actions,
  fetchSubscriptionPlans,
};

export { subscriptionPlansRequests };

export default slice.reducer;
