import {
  DeleteDocument,
  DeleteSubscriptionBeneficiary,
  Dependent,
  Document,
  GetPortalS3UploadSignedUrl,
  GetAllSubscriptionData,
  GetContactCreditCards,
  GetContractSigningRequestStatus,
  GetEmployerContactPersonsFromCustomersApp,
  GetS3DownloadSignedUrl,
  GetSalaryDeductionSigningRequestStatus,
  GetSaleS3DownloadSignedUrl,
  GetS3UploadSignedUrl,
  GetSearchEmployers,
  GetSubscriptionPaymentBreakdown,
  GetSubscriptionPlanOfferings,
  GetSubscriptions,
  MainBeneficiary,
  PatchPaymentMethod,
  PatchSubscriptionBeneficiary,
  PatchSubscriptionMainBeneficiary,
  PatchSubscriptionOriginOfInterest,
  PatchSubscriptionPlans,
  PatchUpdateDependentDocuments,
  PatchUpdateMainBeneficiaryDocuments,
  PaymentMethodEnum,
  PostCreateContractSigningRequest,
  PostCreateSalaryDeductionSigningRequest,
  PostDraftSubscription,
  PostFetchSubscriptionPlansCostBreakdown,
  PostGenerateSubscriptionPaymentOrderId,
  PostSubscriptionBeneficiary,
  PostSubscriptionRequestAfterLogin,
  SigningTicket,
  Subscription,
  SubscriptionPlanEnum,
  UploadDocumentsWithQuestions,
  GetEmployerContactPersonMin,
  PostVerifySubscriptionPayment,
  PatchSubscriptionMainBeneficiaryPostValidSubStatus,
  S3BucketTypeEnum,
  PaymentFrequencyEnum,
} from "@deep-consulting-solutions/be2-constants";
import { apiClient, getClient, silentClient } from "apis";
import {
  ID_PLACEHOLDER,
  OTHER_ORIGIN_OF_INTEREST_ID,
} from "AppCustomer/configs";
import { convertPlansToSlugs, getAgeFromDOB, getLocationInfo } from "helpers";
import { omit } from "lodash";
import { stringify } from "querystring";
import {
  GetActivationFee,
  GetQuarterlyPaymentAmount,
  GetSearchCompanyContacts,
} from "./mock-data";
import { parseSigningTicketURL } from "./helpers";

export const fetchSubscriptionsReq = async () => {
  const res = await getClient().get<typeof GetSubscriptions.Res>(
    GetSubscriptions.ROUTE
  );

  return res.data.data;
};

export const fetchSubscriptionDataReq = async (id: string) => {
  const res = await getClient().get<typeof GetAllSubscriptionData.Res>(
    GetAllSubscriptionData.ROUTE.replace(":subscriptionId", id)
  );
  return res.data.data;
};

export const createSubscription = async (
  subscription?: Subscription,
  plans?: SubscriptionPlanEnum[]
) => {
  const DEFAULT_SUBSCRIPTION = {
    mainBeneficiary: {},
    dependents: [],
    plans: plans ? convertPlansToSlugs(plans) : [],
  };

  const res = await getClient().post<typeof PostDraftSubscription.Res>(
    PostDraftSubscription.ROUTE,
    subscription || DEFAULT_SUBSCRIPTION
  );

  return res.data.data;
};

export const saveSelectedPlans = async (
  subscriptionId: string,
  selectedPlans: SubscriptionPlanEnum[]
) => {
  const res = await getClient().patch<typeof PatchSubscriptionPlans.Res>(
    PatchSubscriptionPlans.ROUTE.replace(":subscriptionId", subscriptionId),
    { plans: convertPlansToSlugs(selectedPlans) }
  );

  return res.data;
};

export const updateMainBeneficiary = async (
  subscriptionId: string,
  mainBeneficiary: Partial<MainBeneficiary>
) => {
  const res = await getClient().patch<
    typeof PatchSubscriptionMainBeneficiary.Res
  >(
    PatchSubscriptionMainBeneficiary.ROUTE.replace(
      ":subscriptionId",
      subscriptionId
    ),
    mainBeneficiary
  );

  return res.data.data;
};

export const updateMainBeneficiaryDashboard = async (
  subscriptionId: string,
  mainBeneficiary: typeof PatchSubscriptionMainBeneficiaryPostValidSubStatus.Body
) => {
  const res = await getClient().patch<
    typeof PatchSubscriptionMainBeneficiaryPostValidSubStatus.Res
  >(
    PatchSubscriptionMainBeneficiaryPostValidSubStatus.ROUTE.replace(
      ":subscriptionId",
      subscriptionId
    ),
    mainBeneficiary
  );

  return res.data.data;
};

export const updateDependentBeneficiary = async (
  subscriptionId: string,
  dependentId: string,
  data: Partial<Dependent>
) => {
  const res = await getClient().patch<typeof PatchSubscriptionBeneficiary.Res>(
    PatchSubscriptionBeneficiary.ROUTE.replace(
      ":subscriptionId",
      subscriptionId
    ).replace(":dependentId", dependentId),
    data
  );
  return res.data.data;
};

export const updateMainBeneficiaryDocumentsReq = async (
  subscriptionId: string,
  documents: UploadDocumentsWithQuestions
) => {
  const res = await getClient().patch<
    typeof PatchUpdateMainBeneficiaryDocuments.Res
  >(
    PatchUpdateMainBeneficiaryDocuments.ROUTE.replace(
      ":subscriptionId",
      subscriptionId
    ),
    documents
  );

  return res.data.data;
};

export const deleteDocument = async (
  subscriptionId: string,
  documentId: string
) => {
  const res = await getClient().delete<typeof DeleteDocument.Res>(
    DeleteDocument.path
      .replace(":subscriptionId", subscriptionId)
      .replace(":documentId", documentId)
  );

  return res.data.data;
};

export const checkIfEmailExists = async (email: string) => {
  const res = await getClient().get<{
    data: { exists: boolean };
  }>(`/contacts/exists?email=${encodeURIComponent(email)}`);

  return res.data.data;
};

export const upsertDependent = async (
  subscriptionId: string,
  dependent: Dependent
) => {
  if (!dependent.id.startsWith(ID_PLACEHOLDER)) {
    const res = await getClient().patch<
      typeof PatchSubscriptionBeneficiary.Res
    >(
      PatchSubscriptionBeneficiary.ROUTE.replace(
        ":subscriptionId",
        subscriptionId
      ).replace(":dependentId", dependent.id),
      omit(dependent, "documents")
    );

    return res.data.data;
  }

  const res = await getClient().post<typeof PostSubscriptionBeneficiary.Res>(
    PostSubscriptionBeneficiary.ROUTE.replace(
      ":subscriptionId",
      subscriptionId
    ),
    omit(dependent, "id", "documents")
  );

  return res.data.data;
};

export const updateDependentDocumentsReq = async (
  subscriptionId: string,
  dependentId: string,
  documents: UploadDocumentsWithQuestions
) => {
  const res = await getClient().patch<typeof PatchUpdateDependentDocuments.Res>(
    PatchUpdateDependentDocuments.ROUTE.replace(
      ":subscriptionId",
      subscriptionId
    ).replace(":dependentId", dependentId),
    documents
  );

  return res.data.data;
};

export const deleteDependent = async (
  subscriptionId: string,
  dependentId: string
) => {
  const res = await getClient().delete<
    typeof DeleteSubscriptionBeneficiary.Res
  >(
    DeleteSubscriptionBeneficiary.ROUTE.replace(
      ":subscriptionId",
      subscriptionId
    ).replace(":dependentId", dependentId)
  );

  return res.data.data;
};

export const fetchSubscriptionPlansOfferingsReq = async (
  subscriptionId: string
) => {
  const res = await getClient().get<typeof GetSubscriptionPlanOfferings.Res>(
    GetSubscriptionPlanOfferings.ROUTE.replace(
      ":subscriptionId",
      subscriptionId
    )
  );

  return res.data.data;
};

export const fetchSubscriptionPlansCostBreakdownReq = async (
  plans: SubscriptionPlanEnum[],
  dependents: Dependent[]
) => {
  const res = await getClient().post<
    typeof PostFetchSubscriptionPlansCostBreakdown.Res
  >(PostFetchSubscriptionPlansCostBreakdown.ROUTE, {
    plans: convertPlansToSlugs(plans),
    dependents: dependents.map((dependent) => ({
      id: dependent.id,
      relation: dependent.relation,
      age: getAgeFromDOB(dependent.dob),
    })),
  });

  return res.data.data;
};

export const fetchS3SignedUrlForUpload = async (
  contentType: string,
  fileName?: string,
  crm?: boolean
) => {
  const queryParams: Record<string, string> = {
    contentType,
    bucketType: S3BucketTypeEnum.DOCUMENT,
  };
  if (fileName) {
    queryParams.fileName = fileName;
  }

  const query =
    queryParams && Object.keys(queryParams).length > 0
      ? Object.entries(queryParams).reduce((acc, [key, value]) => {
          let q = acc;
          q += `${q ? "&" : "?"}${key}=${value}`;
          return q;
        }, "")
      : "";

  if (crm) {
    const res = await getClient(crm).get<typeof GetS3UploadSignedUrl.Res>(
      `${GetS3UploadSignedUrl.path}${query}`
    );
    return res.data.data;
  }
  const res = await getClient().get<typeof GetPortalS3UploadSignedUrl.Res>(
    `${GetPortalS3UploadSignedUrl.path}${query}`
  );
  return res.data.data;
};

export const fetchS3SignedUrlForDownload = async (
  subscriptionId: string,
  documentId: string,
  crm?: boolean
) => {
  if (crm) {
    const res = await getClient(crm).get<typeof GetSaleS3DownloadSignedUrl.Res>(
      GetSaleS3DownloadSignedUrl.path
        .replace(":id", subscriptionId)
        .replace(":documentId", documentId)
    );
    return res.data.data;
  }
  const res = await getClient().get<typeof GetS3DownloadSignedUrl.Res>(
    GetS3DownloadSignedUrl.path
      .replace(":subscriptionId", subscriptionId)
      .replace(":documentId", documentId)
  );
  return res.data.data;
};

export const saveHasUserDocsConsentReq = async () => {
  await new Promise((r) => setTimeout(r, 500));
};

export const saveOriginOfInterest = async ({
  subscriptionId,
  originOfInterestId,
  otherOriginOfInterest,
}: {
  subscriptionId: string;
  originOfInterestId?: string;
  otherOriginOfInterest: string | null;
}) => {
  let requestBody: {
    originOfInterestId?: string;
    otherOriginOfInterest?: string | null;
  } = {};
  if (originOfInterestId === OTHER_ORIGIN_OF_INTEREST_ID) {
    requestBody = { otherOriginOfInterest };
  } else {
    requestBody = { originOfInterestId };
  }

  const res = await getClient().patch<
    typeof PatchSubscriptionOriginOfInterest.Res
  >(
    PatchSubscriptionOriginOfInterest.ROUTE.replace(
      ":subscriptionId",
      subscriptionId
    ),
    requestBody
  );

  return res.data.data;
};

export const fetchDocumentFile = async (document: Document) => {
  await new Promise((r) => setTimeout(r, 1000));

  const response = await fetch("https://placekitten.com/120/120");
  const blob = await response.blob();
  return new File([blob], document.fileName);
};

export const updatePaymentMethodReq = async ({
  paymentMethod,
  subscriptionId,
  paymentFrequency,
}: {
  subscriptionId: string;
  paymentMethod: PaymentMethodEnum;
  paymentFrequency?: PaymentFrequencyEnum;
}) => {
  const body: typeof PatchPaymentMethod.Body = {
    paymentMethod,
    ...(paymentFrequency ? { paymentFrequency } : {}),
  };

  const res = await getClient().patch<typeof PatchPaymentMethod.Res>(
    PatchPaymentMethod.ROUTE.replace(":subscriptionId", subscriptionId),
    body
  );

  return res.data.data;
};

export const searchCompaniesReq = async (searchTerm: string) => {
  const res = await getClient().get<typeof GetSearchEmployers.Res>(
    `${GetSearchEmployers.ROUTE}?term=${searchTerm}`
  );

  return res.data.data;
};

export const searchCompanyContactsReq = async ({
  companyId,
  searchTerm,
}: typeof GetSearchCompanyContacts["Params"]) => {
  const params: typeof GetEmployerContactPersonsFromCustomersApp["Params"] = {
    term: searchTerm,
    limit: 10,
  };

  const res = await getClient().get<
    typeof GetEmployerContactPersonsFromCustomersApp["Res"]
  >(
    `${GetEmployerContactPersonsFromCustomersApp.ROUTE.replace(
      ":id",
      companyId
    )}?${stringify(params)}`
  );
  return res.data.data.contactPersons;
};

export const searchCompanyContactsUnauthReq = async ({
  id,
  ...params
}: typeof GetEmployerContactPersonMin.Params & { id: string }) => {
  const res = await getClient().get<typeof GetEmployerContactPersonMin.Res>(
    `${GetEmployerContactPersonMin.ROUTE.replace(":id", id)}?${stringify(
      omit(params, "by")
    )}`
  );

  return res.data.data.contactPersons;
};

export const fetchContractSigningStatusReq = async (ticketId: string) => {
  const res = await getClient().get<typeof GetContractSigningRequestStatus.Res>(
    GetContractSigningRequestStatus.ROUTE.replace(":ticketId", ticketId)
  );

  return res.data.data;
};

export const fetchContractZohoSignTokenReq = async (subscriptionId: string) => {
  const locationInfo = await getLocationInfo();

  const res = await silentClient.post<
    typeof PostCreateContractSigningRequest.Res
  >(
    PostCreateContractSigningRequest.ROUTE.replace(
      ":subscriptionId",
      subscriptionId
    ),
    { locationInfo }
  );

  const {
    data: { data },
  } = res;

  if ("signingUrl" in data) {
    return parseSigningTicketURL(data);
  }

  return data;
};

export const fetchContractZohoSignUrlReq = async (
  subscriptionId: string,
  otp: string
) => {
  const locationInfo = await getLocationInfo();

  const res = await getClient().post<
    typeof PostCreateContractSigningRequest.Res
  >(
    PostCreateContractSigningRequest.ROUTE.replace(
      ":subscriptionId",
      subscriptionId
    ),
    { otp, locationInfo }
  );

  return parseSigningTicketURL(res.data.data as SigningTicket);
};

export const fetchSalaryDeductionFormSigningStatusReq = async (
  ticketId: string
) => {
  const res = await getClient().get<
    typeof GetSalaryDeductionSigningRequestStatus.Res
  >(
    GetSalaryDeductionSigningRequestStatus.ROUTE.replace(":ticketId", ticketId)
  );

  return res.data.data;
};

export const fetchSalaryDeductionFormZohoSignUrlReq = async (
  subscriptionId: string,
  otp?: string
) => {
  const locationInfo = await getLocationInfo();

  const {
    data: { data },
  } = await silentClient.post<
    typeof PostCreateSalaryDeductionSigningRequest.Res
  >(
    PostCreateSalaryDeductionSigningRequest.ROUTE.replace(
      ":subscriptionId",
      subscriptionId
    ),
    { otp, locationInfo }
  );

  if ("signingUrl" in data) {
    return parseSigningTicketURL(data);
  }

  return data;
};

export const fetchActivationFeeReq = async (subscriptionId: string) => {
  await new Promise((resolve) => setTimeout(resolve, 500));

  // eslint-disable-next-line no-console
  console.log({ subscriptionId });

  const res: typeof GetActivationFee.Res = {
    data: {
      activationFee:
        Math.random() > 0.5 ? Math.floor(Math.random() * 100) : null,
    },
  };

  return res.data;
};

export const fetchQuarterlyPaymentAmountReq = async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));

  const res: typeof GetQuarterlyPaymentAmount.Res = {
    data: {
      quarterlyPaymentAmount:
        Math.random() > 0.5 ? Math.floor(Math.random() * 100) : null,
    },
  };

  return res.data;
};

export const fetchSubscriptionPayments = async () => {
  await new Promise((resolve) => setTimeout(resolve, 500));

  const res = {
    data: [] as unknown[],
  };

  return res.data;
};

export const postSubscriptionRequest = async (
  body: typeof PostSubscriptionRequestAfterLogin.Body,
  trackProgress: (progressEvent: ProgressEvent<EventTarget>) => void
) => {
  const res = await getClient().post<
    typeof PostSubscriptionRequestAfterLogin.Res
  >(PostSubscriptionRequestAfterLogin.ROUTE, body, {
    onDownloadProgress: (progressEvent) => {
      trackProgress(progressEvent);
    },
  });

  return res.data.data;
};

export const fetchCreditCardReq = async (isCRM = false) => {
  const res = await getClient(isCRM).get<typeof GetContactCreditCards.Res>(
    GetContactCreditCards.ROUTE
  );
  return res.data.data;
};

export const getSubscriptionPaymentBreakdown = async (
  subscriptionID: string
) => {
  const res = await apiClient.get<
    typeof GetSubscriptionPaymentBreakdown["Res"]
  >(
    GetSubscriptionPaymentBreakdown.ROUTE.replace(
      ":subscriptionId",
      subscriptionID
    )
  );

  return res.data.data;
};

export const postVerifySubscriptionPayment = async (
  id: string,
  body: typeof PostVerifySubscriptionPayment["Body"]
) => {
  const res = await getClient().post<typeof PostVerifySubscriptionPayment.Res>(
    PostVerifySubscriptionPayment.ROUTE.replace(":subscriptionId", id),
    body
  );
  return res.data.data;
};

export const getSubscriptionPaymentBreakDown = async (id: string) => {
  const res = await apiClient.get<
    typeof GetSubscriptionPaymentBreakdown["Res"]
  >(GetSubscriptionPaymentBreakdown.ROUTE.replace(":subscriptionId", id));
  return res.data.data;
};

export const postGenerateSubscriptionPaymentOrderId = async (
  id: string,
  body: Record<string, unknown>
) => {
  const res = await apiClient.post<
    typeof PostGenerateSubscriptionPaymentOrderId["Res"]
  >(
    PostGenerateSubscriptionPaymentOrderId.ROUTE.replace(":subscriptionId", id),
    body
  );

  return res.data.data;
};

export const setFreeSubscriptionStatusToPendingValidation = async (
  id: string
) => {
  const res = await apiClient.patch(
    "/subscriptions/:subscriptionId/mark-pending-validation".replace(
      ":subscriptionId",
      id
    )
  );

  return res.data.data;
};
