import React, { ReactNode, useCallback } from "react";
import { SharedProps, ProviderContext } from "notistack";
import { makeStyles, IconButton, Box } from "@material-ui/core";
import {
  CheckCircleOutline,
  ReportProblemOutlined,
  ErrorOutlineOutlined,
  InfoOutlined,
  Close,
} from "@material-ui/icons";

const getVariantData = (variant: SharedProps["variant"]) => {
  if (variant === "success") {
    return {
      color: "#4FB056",
      textColor: "white",
      Icon: CheckCircleOutline,
    };
  }
  if (variant === "warning") {
    return {
      color: "#FF9800",
      textColor: "white",
      Icon: ReportProblemOutlined,
    };
  }
  if (variant === "error") {
    return {
      color: "#F44336",
      textColor: "white",
      Icon: ErrorOutlineOutlined,
    };
  }
  return {
    color: "#263E7F",
    textColor: "white",
    Icon: InfoOutlined,
  };
};

interface StyleProps {
  color: string;
  textColor: string;
}

const useStyle = makeStyles(({ spacing: s, breakpoints: b }) => ({
  wrapper: ({ color, textColor }: StyleProps) => ({
    background: color,
    padding: s(0, 1),
    borderLeftWidth: 10,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: color,
    borderStyle: "solid",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    color: textColor,

    [b.down("sm")]: {
      marginLeft: s(1),
      maxWidth: "300px",
    },
  }),
  messageWrapper: {
    flex: 1,
    wordWrap: "break-word",
    overflow: "hidden",
  },
  icon: ({ textColor }: StyleProps) => ({
    marginRight: s(1),
    color: textColor,
  }),
  closeIconWrapper: {
    marginLeft: "auto",
  },
  closeIcon: ({ textColor }: StyleProps) => ({
    marginRight: s(-1),
    marginLeft: s(1),
    color: textColor,
  }),
}));

interface Props {
  variant: SharedProps["variant"];
  message: ReactNode;
  messageKey: number | string;
  notiRef: ProviderContext | null;
}

const NotificationDisplay = React.forwardRef<any, Props>(
  ({ variant, message, notiRef, messageKey }, ref) => {
    const { color, textColor, Icon } = getVariantData(variant);
    const classes = useStyle({ color, textColor });

    const onCloseClick = useCallback(() => {
      notiRef?.closeSnackbar(messageKey);
    }, [messageKey, notiRef]);

    return (
      <div ref={ref} className={classes.wrapper}>
        <Icon className={classes.icon} />
        <Box className={classes.messageWrapper}>{message}</Box>
        <Box className={classes.closeIconWrapper}>
          <IconButton
            className={classes.closeIcon}
            onClick={onCloseClick}
            aria-label="close notification"
          >
            <Close />
          </IconButton>
        </Box>
      </div>
    );
  }
);

export default NotificationDisplay;
