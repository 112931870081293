import { GetOriginOfInterests } from "@deep-consulting-solutions/be2-constants";
import { getClient } from "apis";

export const fetchOriginsOfInterestsReq = async () => {
  const res = await getClient().get<typeof GetOriginOfInterests.Res>(
    GetOriginOfInterests.ROUTE
  );

  return res.data.data;
};
