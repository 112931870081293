import * as Yup from "yup";
import { differenceInYears } from "date-fns";
import {
  simpleValidatePhoneNumber,
  validatePhoneNumberWithCountryCode,
} from "@deep-consulting-solutions/dcs-web-ui";
import {
  GenderEnum,
  BloodTypeEnum,
  RefundRequestTypeEnum,
} from "@deep-consulting-solutions/be2-constants";

import { getTomorrow } from "./dates";
import { getENText } from "./languages";

export const VALIDATIONS = {
  isNew: Yup.boolean().required(),
  email: Yup.string()
    .email(getENText("validation.email.invalid"))
    .required(getENText("validation.email.required")),
  emailNotRequired: Yup.string<string>().email(
    getENText("validation.email.invalid")
  ),
  firstName: Yup.string().required(getENText("validation.firstName.required")),
  firstNameNotRequired: Yup.string<string>(),
  lastName: Yup.string().required(getENText("validation.lastName.required")),
  lastNameNotRequired: Yup.string<string>(),
  phone: Yup.string()
    .required(getENText("validation.phone.required"))
    .test(
      "check validity",
      getENText("validation.phone.invalid"),
      validatePhoneNumberWithCountryCode
    ),
  phoneNotRequired: Yup.string<string>().test(
    "check validity",
    getENText("validation.phone.invalid"),
    simpleValidatePhoneNumber
  ),
  passwordLogin: Yup.string().required(
    getENText("validation.password.required")
  ),
  password: Yup.string()
    .min(8, getENText("validation.password.min"))
    .required(getENText("validation.password.required")),
  repassword: Yup.string()
    .required(getENText("validation.repassword.required"))
    .oneOf([Yup.ref("password")], getENText("validation.repassword.match")),
  otp: Yup.string()
    .required(getENText("validation.otp.required"))
    .length(6, getENText("validation.otp.invalid")),
  street: Yup.string().required(getENText("validation.street.required")),
  city: Yup.string<string>(),
  island: Yup.string()
    .typeError(getENText("validation.island.typeError"))
    .required(getENText("validation.island.required")),
  islandNullable: Yup.string()
    .required(getENText("validation.island.required"))
    .nullable(),
  state: Yup.string()
    .typeError(getENText("validation.state.typeError"))
    .required(getENText("validation.state.required"))
    .nullable(),
  region: Yup.string()
    .typeError(getENText("validation.region.typeError"))
    .required(getENText("validation.region.required"))
    .nullable(),
  poBox: Yup.string<string>(),
  country: Yup.string().required(getENText("validation.country.required")),
  other: Yup.string(),
  zip: Yup.string().required(getENText("validation.zip.required")),
  dob: Yup.date().required(getENText("validation.dob.required")).nullable(),
  dobAdult: Yup.date()
    .required(getENText("validation.dob.required"))
    .nullable()
    .test(
      "isAdult",
      getENText("validation.dob.isAdult"),
      (value) => !!value && differenceInYears(new Date(), new Date(value)) >= 18
    ),
  gender: Yup.string<GenderEnum>().required(
    getENText("validation.gender.required")
  ),
  genderNotRequired: Yup.string<GenderEnum>(),
  citizenship: Yup.array()
    .required(getENText("validation.citizenship.required"))
    .of(Yup.string().required())
    .min(1, getENText("validation.citizenship.required")),
  citizenshipNotRequired: Yup.array<string>(),
  insuranceProvider: Yup.string<string>(),
  policyNumber: Yup.string<string>(),
  reason: Yup.string().required(getENText("validation.reason.required")),
  documentType: Yup.string().required(
    getENText("validation.documentType.required")
  ),
  expiryDate: Yup.date()
    .required(getENText("validation.expiryDate.required"))
    .nullable(),
  document: Yup.string().required(getENText("validation.document.required")),
  companyName: Yup.string().required(
    getENText("validation.companyName.required")
  ),
  headquartersStreet: Yup.string().required(
    getENText("validation.headquartersStreet.required")
  ),
  headquartersCity: Yup.string().required(
    getENText("validation.headquartersCity.required")
  ),
  headquartersState: Yup.string().required(
    getENText("validation.headquartersState.required")
  ),
  headquartersCountry: Yup.string().required(
    getENText("validation.headquartersCountry.required")
  ),
  employer: Yup.object({
    id: Yup.string().required(),
  })
    .required(getENText("validation.employer.required"))
    .nullable(),
  employerName: Yup.string().required(
    getENText("validation.employer.name.required")
  ),
  relation: Yup.string().required(getENText("validation.relation.required")),
  bloodType: Yup.string<BloodTypeEnum>(),
  preferredHospital: Yup.string().required(
    getENText("validation.preferredHospital.required")
  ),
  company: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
  })
    .required(getENText("validation.company.required"))
    .nullable(),
  companyNotRequired: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
  })
    .defined()
    .nullable(),
  employmentStatus: Yup.string().required(
    getENText("validation.employmentStatus.required")
  ),
  faxNotRequired: Yup.string<string>(),
  departmentInCompany: Yup.string<string>().required(
    getENText("validation.employee.departmentInCompany.required")
  ),
  divisionDepartment: Yup.string<string>(),
  nib: Yup.string(),
  employeePosition: Yup.string().required(
    getENText("validation.employee.position.required")
  ),
  salaryNumber: Yup.string().required(
    getENText("validation.employee.salaryRollNumber.required")
  ),
  commencementDate: Yup.date().nullable(true),
  contactPerson: Yup.object({
    id: Yup.string().required(),
  }),
  membershipNumber: Yup.string().required(
    getENText("validation.membershipNumber.required")
  ),
  membershipNumberNotRequired: Yup.string(),
  isInadmissibleToEnterUs: Yup.boolean().required(),
  isEligibleForFreeVisa: Yup.boolean().required(),
  isContactPersonVerified: Yup.boolean().required(),

  correctAmount: Yup.number()
    .required(getENText("validation.correctAmount.required"))
    .nullable(),
  correctAmountNotRequired: Yup.number<number>(),

  outstandingPaymentDate: Yup.date()
    .min(getTomorrow(), getENText("validation.date.minTomorrow"))
    .required(getENText("validation.outstandingPaymentDate.required"))
    .typeError(getENText("validation.outstandingPaymentDate.typeError")),
  outstandingPaymentDateNotRequired: Yup.date<Date>(),

  revisedListDate: Yup.date()
    .required(getENText("validation.revisedListDate.required"))
    .min(getTomorrow(), getENText("validation.date.minTomorrow"))
    .typeError(getENText("validation.revisedListDate.typeError")),
  revisedListDateNotRequired: Yup.date<Date>(),

  refundRequestType: Yup.string()
    .oneOf(Object.values(RefundRequestTypeEnum))
    .required(getENText("validation.refundRequestType.required")),
  refundRequestTypeNotRequired: Yup.string(),
};
