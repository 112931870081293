import { COUNTRIES_BY_CODE } from "configs";

export const formatAddress = ({
  street,
  city,
  state,
  country,
  poBox,
}: {
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  poBox?: string;
}) => {
  return [street, poBox, city, state, country]
    .filter((item) => item)
    .join(", ");
};

export const getCountryCode = (() => {
  const COUNTRY_TO_CODE: { [countryName: string]: string } = {};

  Object.entries(COUNTRIES_BY_CODE).forEach(([code, { label }]) => {
    COUNTRY_TO_CODE[label.toLowerCase()] = code;
  });

  return (countryName?: string | null) => {
    if (!countryName) return null;
    return COUNTRY_TO_CODE[countryName.toLowerCase()] || null;
  };
})();

export const codeToCountry = (code: string) => {
  const country = COUNTRIES_BY_CODE[code];
  if (!country) return "";
  return country.label;
};
