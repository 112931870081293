import {
  Document,
  PlanSlug,
  Subscription,
  SigningTicket,
  UploadDocument,
  PaymentMethodEnum,
  PostSubscriptionRequestAfterLogin,
  AllDataForSingleSubscription,
  PaymentFrequencyEnum,
  SubscriptionWithDetails,
} from "@deep-consulting-solutions/be2-constants";

import {
  ID_PLACEHOLDER,
  OTHER_ORIGIN_OF_INTEREST_ID,
} from "AppCustomer/configs";
import { omit } from "lodash";
import { convertPlansToSlugs } from "helpers";

import { SubRequestState } from "../types";

export const convertSubscriptionToSubscriptionState = (
  subscription:
    | (Subscription &
        Partial<SubscriptionWithDetails> &
        Partial<SubRequestState>)
    | null
) => {
  if (subscription) {
    const {
      originOfInterest,
      otherOriginOfInterest,
      selectedPlans,
      paymentDetails,
      paymentMethod,
      paymentStatus,
      paymentFrequency,
      ...rest
    } = subscription;

    let origin: typeof originOfInterest;
    if (otherOriginOfInterest) {
      origin = {
        id: OTHER_ORIGIN_OF_INTEREST_ID,
        name: OTHER_ORIGIN_OF_INTEREST_ID,
      };
    } else {
      origin = originOfInterest;
    }

    const res = {
      originOfInterest: origin,
      otherOriginOfInterest,
      selectedPlans: selectedPlans.length ? selectedPlans : [],
      paymentDetails,
      paymentStatus: paymentDetails?.subscripionPaymentStatus || paymentStatus,
      paymentMethod: paymentDetails?.paymentMethod || paymentMethod,
      paymentFrequency:
        paymentDetails?.paymentFrequency ||
        (paymentDetails?.recurringPeriod as PaymentFrequencyEnum) ||
        paymentFrequency,
      ...rest,
    };
    return res;
  }
};

export const convertSubscriptionAllDataToSubscriptionState = (
  subscription: (AllDataForSingleSubscription & SubRequestState) | null
) => {
  if (subscription) {
    const {
      originOfInterest,
      otherOriginOfInterest,
      selectedPlans,
      paymentStatus,
      paymentDetails,
      paymentMethod,
      paymentFrequency,
      ...rest
    } = subscription;

    let origin: typeof originOfInterest;
    if (otherOriginOfInterest) {
      origin = {
        id: OTHER_ORIGIN_OF_INTEREST_ID,
        name: OTHER_ORIGIN_OF_INTEREST_ID,
      };
    } else {
      origin = originOfInterest;
    }

    const res = {
      originOfInterest: origin,
      otherOriginOfInterest,
      selectedPlans: selectedPlans.length ? selectedPlans : [],
      paymentDetails,
      paymentStatus: paymentDetails?.subscripionPaymentStatus || paymentStatus,
      paymentMethod: paymentDetails?.paymentMethod || paymentMethod,
      paymentFrequency:
        paymentDetails?.paymentFrequency ||
        (paymentDetails?.recurringPeriod as PaymentFrequencyEnum) ||
        paymentFrequency,
      ...rest,
    };
    return res;
  }
};

export const parseSigningTicketURL = (ticket: SigningTicket) => {
  const { signingUrl } = ticket;
  const newURL = new URL(signingUrl);
  newURL.searchParams.set("frameorigin", window.location.origin);
  ticket.signingUrl = decodeURIComponent(newURL.toString());
  return ticket;
};

// Remove id from new documents so they can be recognized.
export const prepareDocumentsPayload = (
  existingDocuments: Document[] | undefined,
  newDocuments: (Document | UploadDocument)[]
) => {
  return newDocuments.map((document) => {
    if (existingDocuments?.find(({ id }) => id === document.id)) {
      return document;
    }

    document.id = undefined;
    return document;
  });
};

export const preparePostLoginPayload = (state: SubRequestState) => {
  const {
    dependents,
    selectedPlans,
    paymentMethod,
    mainBeneficiary,
    originOfInterest,
    otherOriginOfInterest,
  } = state;

  const companyId = mainBeneficiary?.company?.id;
  const companyName = mainBeneficiary?.company?.name;

  const subscription: typeof PostSubscriptionRequestAfterLogin.Body = {
    ...(!originOfInterest?.id ||
    originOfInterest?.id === OTHER_ORIGIN_OF_INTEREST_ID
      ? { otherOriginOfInterest: otherOriginOfInterest || undefined }
      : {
          originOfInterestId: originOfInterest?.id,
        }),
    plans: convertPlansToSlugs(selectedPlans) as PlanSlug[],
    paymentMethod: paymentMethod || PaymentMethodEnum.DIRECT_PAYMENT,
    dependents: dependents.map((dependent) => ({
      ...omit(dependent, [
        "documents",
        "isEligibleForFreeVisa",
        "isInadmissibleToEnterUs",
      ]),
      id: dependent.id.replace(ID_PLACEHOLDER, ""),
    })),
    mainBeneficiary: {
      ...omit(mainBeneficiary, [
        "id",
        "zohoId",
        "company",
        "employee",
        "isClaimed",
        "documents",
        "doNotContact",
        "preferredHospital",
        "isEligibleForFreeVisa",
        "isInadmissibleToEnterUs",
      ]),
      preferredHospitalId: mainBeneficiary?.preferredHospital?.id,
      ...(mainBeneficiary?.company?.isNew
        ? {
            newCompanyName: companyName,
          }
        : { companyId }),
      ...(paymentMethod === PaymentMethodEnum.SALARY_DEDUCTION && {
        employmentInfo: {
          ...(mainBeneficiary?.company?.isNew ? {} : { companyId }),
          companyName,
          companyDivision: mainBeneficiary?.company?.department,
          companyAddress: {
            city: mainBeneficiary?.company?.headquartersCity,
            pobox: mainBeneficiary?.company?.headquartersPoBox,
            island: mainBeneficiary?.company?.headquartersState,
            street: mainBeneficiary?.company?.headquartersStreet,
            country: mainBeneficiary?.company?.headquartersCountry,
          },
          contactPerson: {
            fax: mainBeneficiary?.employee?.contactPerson.fax,
            email: mainBeneficiary?.employee?.contactPerson.email,
            phone: mainBeneficiary?.employee?.contactPerson.phone,
            lastName: mainBeneficiary?.employee?.contactPerson.lastName,
            firstName: mainBeneficiary?.employee?.contactPerson.firstName,
            id:
              mainBeneficiary?.employee?.contactPerson.id?.trim() || undefined,
          },
          employmentDetails: {
            nib: mainBeneficiary?.employee?.nib || "",
            department: mainBeneficiary?.employee?.department || "",
            position: mainBeneficiary?.employee?.position || "",
            salaryRollNumber: mainBeneficiary?.employee?.salaryRollNumber || "",
            employmentCommencementDate:
              mainBeneficiary?.employee?.employmentCommencementDate,
          },
        },
      }),
    },
  };

  return subscription;
};
