export function encryptStringWithKey(str: string, key: string) {
  let result = "";
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    const charCode = str.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    result += String.fromCharCode(charCode);
  }
  return result;
}

export function decryptStringWithKey(
  str: string | null | undefined,
  key?: string | null | undefined
) {
  try {
    if (!str || !key) {
      return null;
    }
    let result = "";
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < str.length; i++) {
      // eslint-disable-next-line no-bitwise
      const charCode = str.charCodeAt(i) ^ key.charCodeAt(i % key.length);
      result += String.fromCharCode(charCode);
    }
    return result;
  } catch {
    return null;
  }
}
